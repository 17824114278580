import React, { useEffect, useMemo } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Message } from 'primereact/message';
import { useDispatch, useSelector } from 'react-redux';
import { updateFilter } from '@/store/export/exportSlice';
import { selectExport } from '@/store/export/exportSelectors';
import {
  bannedKeys,
  filterDictionary,
  getQueryParams,
} from '@/utils/helpers/export';
import { AppDispatch } from '@/store';
import CheckboxSelection from '@/components/ExportComponent/CheckboxSelection';
import { FilterItem } from '@/components/ExportComponent/FilterItem';

const ColumnsAndFilters: React.FC = () => {
  const { columns, filters = {} } = useSelector(selectExport);
  const dispatch = useDispatch<AppDispatch>();

  const queryParams = useMemo(() => getQueryParams(window.location.search), []);

  useEffect(() => {
    const formattedParams = {
      ...queryParams,
      ...(queryParams.role && { role: Number(queryParams.role) }),
    };

    dispatch(updateFilter(formattedParams));
  }, [dispatch, queryParams]);

  const filteredEntries = useMemo(
    () =>
      Object.entries(filters).filter(
        ([key]) => !bannedKeys.some((bannedKey) => key.includes(bannedKey))
      ),
    [filters]
  );

  const renderFilters = () => {
    if (filteredEntries.length === 0) {
      return <p className="italic">Henüz bir filtre eklenmemiş.</p>;
    }

    return (
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {filteredEntries.map(([key, value]) => (
          <FilterItem
            key={key}
            filterKey={key}
            value={value}
            dictionary={filterDictionary}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <Fieldset legend="Sütunlar" className="p-4 relative mt-4">
        <CheckboxSelection />
        {columns.length === 0 && (
          <p className="italic text-red-600">
            Bu modüle ait sütunlar getirilirken bir hata oluştu.
          </p>
        )}
      </Fieldset>

      <Fieldset
        legend="Filtreler"
        className="p-4 border border-gray-300 rounded-lg dark:border-gray-700 dark:bg-gray-800"
      >
        {renderFilters()}
      </Fieldset>

      <Message
        text="Filtrelerinizi ve sütunlarınızı kontrol edin, ardından dışarı aktar butonuna tıklayın. En az bir sütun seçmelisiniz."
        className="border-primary w-full justify-content-start"
        severity="warn"
      />
    </>
  );
};

export default React.memo(ColumnsAndFilters);
