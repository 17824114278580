import { useLocation, useNavigate } from 'react-router-dom';

export const getCurrentPath = () => window.location.pathname;

export const navigateToSignin = () => {
  const signInUrl = '/signin';
  if (!getCurrentPath().includes(signInUrl)) {
    window.location.href = signInUrl;
  }
};

export const navigateToDashboard = () => {
  const dashboardUrl = '/';

  if (getCurrentPath() !== '/') {
    window.location.href = dashboardUrl;
  }
};

export const getBasePath = () => {
  return getCurrentPath().split('/').filter(Boolean)[0] || '/';
};

/*
  Handles navigation based on state value and base path.
  @param basePath The base path for navigation (e.g., 'question-pool'). 
*/

export const useDynamicNavigate = (basePath?: string) => {
  const location = useLocation();
  const navigate = useNavigate();
  const BASE_PATH = basePath || getBasePath();

  const handleNavigation = () => {
    const { state } = location;

    if (state) {
      navigate(`/${BASE_PATH}/${state}`);
    } else {
      navigate(`/${BASE_PATH}`);
    }
  };

  return handleNavigation;
};
