import React, { useState, useEffect } from 'react';
import {
  Tree,
  TreeCheckboxSelectionKeys,
  TreeSelectionEvent,
} from 'primereact/tree';
import { TreeNode } from 'primereact/treenode';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { selectExport } from '@/store/export/exportSelectors';
import { updateSelectedKeys } from '@/store/export/exportSlice';
import { transformDataToTreeNodes } from '@/utils/helpers/export';

const CheckboxSelection = () => {
  const dispatch = useDispatch();
  const { columns, selectedKeys } = useSelector(selectExport);

  const [nodes, setNodes] = useState<TreeNode[]>([]);

  useEffect(() => {
    const treeNodes = transformDataToTreeNodes(columns);
    const sortedNodes = treeNodes.sort((a) => (a.children ? -1 : 1));
    setNodes(sortedNodes);
  }, [columns]);

  const handleSelectionChange = (e: TreeSelectionEvent) => {
    const value = e.value as TreeCheckboxSelectionKeys;
    dispatch(updateSelectedKeys(value));
  };

  const selectAll = (treeNodes: TreeNode[]) => {
    const allKeys: TreeCheckboxSelectionKeys = {};
    const traverse = (treeData: TreeNode[]) => {
      treeData.forEach((node) => {
        allKeys[node.key as string] = { checked: true };
        if (node.children) {
          traverse(node.children);
        }
      });
    };
    traverse(treeNodes);
    dispatch(updateSelectedKeys(allKeys));
  };

  const deselectAll = () => {
    dispatch(updateSelectedKeys({}));
  };

  return (
    <div className="flex justify-center items-center p-2">
      <div className="border border-gray-200 dark:border-gray-700 rounded-lg p-4 w-full">
        <Tree
          value={nodes}
          filter
          filterMode="strict"
          selectionMode="checkbox"
          filterPlaceholder="Ara"
          selectionKeys={selectedKeys}
          onSelectionChange={handleSelectionChange}
          className="w-full max-h-[500px] overflow-y-auto !border-none rounded-lg [&>ul]:grid [&>ul]:grid-cols-1 lg:[&>ul]:grid-cols-2"
        />
        <div className="flex justify-end my-4 gap-2 md:flex-row flex-col">
          <Button
            size="small"
            outlined
            type="button"
            onClick={() => selectAll(nodes)}
            label="Tümünü Seç"
            icon="pi pi-check"
          />
          <Button
            size="small"
            outlined
            type="button"
            onClick={deselectAll}
            label="Tümünü Temizle"
            icon="pi pi-times"
          />
        </div>
      </div>
    </div>
  );
};

export default CheckboxSelection;
