import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import navigationReducer from './navigation';
import { baseApi } from '@/services/baseApi';
import exportReducer from './export/exportSlice';
import commonSliceReducer from './common/commonSlice';
import { rtkQueryErrorLogger } from '@/services/errorMiddleware';

export const rootReducer = combineReducers({
  common: commonSliceReducer,
  navigation: navigationReducer,
  export: exportReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      baseApi.middleware,
      rtkQueryErrorLogger as any
    );
  },
  devTools: process.env.NODE_ENV === 'development',
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = AppStore['dispatch'];
