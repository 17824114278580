import { IPageResponse } from '@/types/common.types';
import {
  IClassesResponse,
  IClassesParams,
  IClassDetailResponse,
  IClassesByCampusesResponse,
} from './types';
import TAG_TYPES from '@/utils/constants/tagTypes';
import { baseApi } from '../baseApi';

export const classesApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    classesOverview: builder.query<
      IPageResponse<IClassesResponse>,
      IClassesParams
    >({
      query: (classesParams) => ({
        url: `/classes/overview`,
        headers: {
          CampusID: classesParams.campusID,
        },
        params: { ...classesParams, campusID: undefined },
      }),
      providesTags: [TAG_TYPES.CLASSES],
    }),

    classesByCampuses: builder.query<
      IClassesByCampusesResponse[],
      { campusID: string; campusIDList: string[] }
    >({
      query: ({ campusIDList, campusID }) => ({
        url: `/classes/classes-by-campuses`,
        method: 'POST',
        data: {
          campusIDList,
        },
        headers: {
          campusID,
        },
      }),
      providesTags: (result, error, args) => [
        { type: TAG_TYPES.CLASSES, id: args.campusID },
      ],
    }),
    classDetail: builder.query<
      IClassDetailResponse,
      { CampusID?: string; classID?: string }
    >({
      query: ({ CampusID, classID }) => ({
        url: `/classes/${classID}`,
        headers: {
          CampusID,
        },
      }),
      providesTags: [TAG_TYPES.CLASSES],
    }),
    updateClass: builder.mutation<unknown, IClassDetailResponse>({
      query: ({ campusID, classID, ...updateClassParams }) => ({
        url: `/classes/${classID}`,
        method: 'PUT',
        headers: {
          CampusID: campusID,
        },
        data: updateClassParams,
      }),
      invalidatesTags: (result, error, args) => [
        { type: TAG_TYPES.CLASSES, id: args.classID },
        { type: TAG_TYPES.CLASSES },
        TAG_TYPES.ORGANIZATIONS,
      ],
    }),
    deleteClass: builder.mutation<
      unknown,
      { campusID: string; classID: string }
    >({
      query: ({ campusID, classID }) => ({
        url: `/classes/${classID}`,
        method: 'DELETE',
        headers: {
          CampusID: campusID,
        },
      }),
      invalidatesTags: (result, error, args) => [
        { type: TAG_TYPES.CLASSES, id: args.classID },
        { type: TAG_TYPES.CLASSES },
      ],
    }),
  }),
});

export const {
  useClassesOverviewQuery,
  useClassDetailQuery,
  useClassesByCampusesQuery,
  useLazyClassesByCampusesQuery,
  useLazyClassDetailQuery,
  useUpdateClassMutation,
  useDeleteClassMutation,
} = classesApi;
