import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Message } from 'primereact/message';
import ControlledInputText from '@/components/FormInputs/ControlledInputText';
import ControlledInputTextArea from '@/components/FormInputs/ControlledInputTextArea';
import ControlledCheckbox from '@/components/FormInputs/ControlledCheckbox';
import CustomButton from '@/components/CustomButton';
import ControlledDropdown from '@/components/FormInputs/ControlledDropdown';
import { useCreateClassTrackingNoteMutation } from '@/services/trackingNote';
import {
  ICreateTrackingNoteClassesRequest,
  IUpdateTrackingNoteRequest,
} from '@/services/trackingNote/types';
import { useNormalizedLessons } from '@/utils/hooks/useNormalizedLessons';
import ControlledPickList from '@/components/FormInputs/ControlledPickList';
import { useMyCampusesQuery } from '@/services/users';
import {
  useOrgCampusDepartmentQuery,
  useOrgCampusGradeClassesQuery,
  useOrgCampusGradesQuery,
} from '@/services/organizations';
import { useLazyStudentsQuery } from '@/services/students';
import { normalizedStudentData } from '@/utils/helpers/student';
import { useLazyClassDetailQuery } from '@/services/classes';
import trackingNoteClassFormValidations from '@/components/TrackingNote/trackingNoteClassForm.validations';
import { addToast } from '@/utils/helpers/toast';
import ControlledUploader from '@/components/FormInputs/ControlledUploader';
import { FolderType } from '@/services/files/types';
import { isProduction } from '@/routes/Routes';
import { hasPermission } from '@/utils/helpers/userPermission';
import { Operations } from '@/utils/enums/Operations';
import { useDynamicNavigate } from '@/utils/helpers/navigate';
import { findMaxCharacterLimit } from '@/utils/helpers/form';
import trackingNoteFormValidations from '@/components/TrackingNote/trackingNoteForm.validations';
import { IStudent } from '@/services/students/types';

const TrackingNoteClassesForm = () => {
  const { id: entityID } = useParams<{
    id: string;
  }>();
  const navigate = useDynamicNavigate(`classes/${entityID}/tracking-note`);
  const [studentOptions, setStudentOptions] = useState<
    { name: string; id: string }[]
  >([]);

  const [fetchStudents] = useLazyStudentsQuery();
  const [getClassDetail] = useLazyClassDetailQuery();

  const { control, handleSubmit, setValue, watch, reset } = useForm<
    ICreateTrackingNoteClassesRequest | IUpdateTrackingNoteRequest
  >({
    mode: 'onBlur',
    resolver: yupResolver(trackingNoteClassFormValidations),
    defaultValues: {
      students: [],
      classID: entityID,
      campusID: '',
      departmentID: '',
      gradeID: '',
      lessonID: '',
    },
    resetOptions: {
      keepDefaultValues: true,
    },
  });

  useEffect(() => {
    if (entityID) {
      getClassDetail({ classID: entityID })
        .unwrap()
        .then((classData) => {
          reset({
            classID: entityID,
            campusID: classData.campusID,
            departmentID: classData.departmentID,
            gradeID: classData.gradeID,
          });
        })
        .catch(() => {
          reset({});
        });
    }
  }, [getClassDetail, reset, entityID]);

  const { data: myCampuses, isFetching: isMyCampusesLoading } =
    useMyCampusesQuery();

  const watchCampusID = watch('campusID');

  const {
    data: orgCampusDepartments,
    isFetching: isOrgCampusDepartmentsFetching,
  } = useOrgCampusDepartmentQuery(
    {
      campusID: watchCampusID,
    },
    {
      skip: !watchCampusID,
    }
  );

  const watchDepartmentID = watch('departmentID');
  const { data: orgCampusGrades, isFetching: isOrgCampusGradesFetching } =
    useOrgCampusGradesQuery(
      {
        departmentID: watchDepartmentID,
      },
      {
        skip: !watchDepartmentID,
      }
    );

  const watchGradeID = watch('gradeID');

  const normalizedLessons = useNormalizedLessons({
    entityID,
    platform: 'classes',
  });

  const {
    data: orgCampusGradeClasses,
    isFetching: isOrgCampusGradeClassesFetching,
  } = useOrgCampusGradeClassesQuery(
    {
      campusID: watchCampusID,
      departmentID: watchDepartmentID,
      gradeID: watchGradeID,
    },
    {
      skip: !watchDepartmentID || !watchGradeID || !watchCampusID,
    }
  );
  const watchClassID = watch('classID');

  const fetchStudentsForClass = async () => {
    const selectedClassID = watchClassID || [];
    const students = await fetchStudents({
      page: 1,
      perPage: 100,
      campusID: watchCampusID,
      departmentID: watchDepartmentID,
      gradeID: watchGradeID,
      classID: selectedClassID as string,
    }).unwrap();

    setStudentOptions(
      normalizedStudentData((students.items as unknown as IStudent[]) || [])
    );
  };

  useEffect(() => {
    if (
      !watchClassID ||
      !watchCampusID ||
      !watchDepartmentID ||
      !watchGradeID
    ) {
      return;
    }
    fetchStudentsForClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchClassID, watchCampusID, watchDepartmentID, watchGradeID, setValue]);

  const [createTrackingClassesNote] = useCreateClassTrackingNoteMutation();

  const handleCreateTrackingNote = async (
    data: ICreateTrackingNoteClassesRequest
  ) => {
    return createTrackingClassesNote(data)
      .unwrap()
      .then(() => {
        addToast({
          severity: 'success',
          summary: 'Başarılı',
          detail: 'Takip Notu başarıyla oluşturuldu',
        });
        navigate();
      });
  };

  const watchOnlyMeVisibility = watch('creatorOnlyVisibility');
  useEffect(() => {
    if (watchOnlyMeVisibility) {
      setValue('studentVisibility', false);
      setValue('parentVisibility', false);
      setValue('managementVisibility', false);
      setValue('counselorVisibility', false);
    }
  }, [watchOnlyMeVisibility, setValue]);

  const renderStudentItemTemplate = (item: { name: string }) => (
    <div>
      <i className="pi pi-user mr-4" />
      {item.name}
    </div>
  );

  return (
    <form
      onSubmit={handleSubmit(handleCreateTrackingNote)}
      className="grid grid-cols-3 gap-6 max-w-screen-lg mx-auto border border-gray-200 dark:border-[#424b57] border-solid rounded-md p-12"
    >
      <ControlledDropdown
        control={control}
        name="lessonID"
        label="Ders"
        filter
        placeholder="Ders Seçiniz"
        options={normalizedLessons}
        optionLabel="name"
        optionValue="id"
        wrapperClassName="col-span-full"
      />
      <ControlledDropdown
        control={control}
        name="campusID"
        label="Kampüs"
        placeholder="Kampüs Seçiniz"
        options={myCampuses?.items || []}
        optionLabel="name"
        optionValue="id"
        loading={isMyCampusesLoading}
        wrapperClassName="col-span-full"
        disabled
      />
      <ControlledDropdown
        control={control}
        name="departmentID"
        options={orgCampusDepartments?.items || []}
        loading={isOrgCampusDepartmentsFetching}
        label="Birim"
        placeholder="Birim Seçiniz"
        wrapperClassName="col-span-full"
        disabled
      />
      <ControlledDropdown
        control={control}
        name="gradeID"
        options={orgCampusGrades?.items || []}
        loading={isOrgCampusGradesFetching}
        label="Sınıf"
        placeholder="Sınıf Seçiniz"
        wrapperClassName="col-span-full"
        disabled
      />
      <ControlledDropdown
        control={control}
        name="classID"
        label="Şube"
        placeholder="Şube Seçiniz"
        options={orgCampusGradeClasses?.items || []}
        loading={isOrgCampusGradeClassesFetching}
        wrapperClassName="col-span-full"
        disabled
      />
      <ControlledPickList
        control={control}
        name="students"
        options={studentOptions}
        label="Öğrenciler"
        sourceHeader="Mevcut Öğrenciler"
        targetHeader="Seçilen Öğrenciler"
        sourceFilterPlaceholder="Öğrenciler ismi ile ara"
        targetFilterPlaceholder="Öğrenciler ismi ile ara"
        showSourceControls={false}
        showTargetControls={false}
        wrapperClassName="col-span-full"
        itemTemplate={renderStudentItemTemplate}
      />

      <ControlledInputText
        control={control}
        name="title"
        label="Başlık"
        placeholder="Başlık Giriniz"
        wrapperClassName="col-span-full"
        maxCharLimit={
          findMaxCharacterLimit(trackingNoteFormValidations, 'title') || 255
        }
      />
      <ControlledInputTextArea
        control={control}
        name="description"
        label="Açıklama"
        placeholder="Açıklama Giriniz"
        wrapperClassName="col-span-full"
        maxCharLimit={
          findMaxCharacterLimit(trackingNoteFormValidations, 'description') ||
          10000
        }
      />
      <ControlledUploader
        control={control}
        name="attachment"
        label="Dosya Ekle"
        folder={FolderType.Students}
        wrapperClassName="col-span-full"
        accept={{
          'application/pdf': ['.pdf'],
          'application/img': ['.jpg', '.jpeg', '.png'],
        }}
        maxFiles={1}
      />

      <div className="col-span-full flex flex-col gap-4">
        <label className="text-sm text-gray-700 dark:text-gray-300">
          Kimler Görsün:{' '}
        </label>
        <Message
          severity="warn"
          className="flex justify-start"
          text="Sadece ben görüntüleyeyim, Yöneticim görsün veya Rehberlik Birimleri görsün seçeneklerinden en az 1 tanesi zorunludur"
        />
        <div className="flex flex-col gap-4 bg-gray-100 dark:bg-[#424b57] p-4 rounded-md">
          <ControlledCheckbox
            control={control}
            label="Öğrenci Görsün"
            name="studentVisibility"
            disabled={watchOnlyMeVisibility}
          />

          <ControlledCheckbox
            control={control}
            label="Veli Görsün"
            name="parentVisibility"
            disabled={watchOnlyMeVisibility}
          />

          <ControlledCheckbox
            control={control}
            label="Yöneticim Görsün"
            name="managementVisibility"
            disabled={watchOnlyMeVisibility}
          />

          <ControlledCheckbox
            control={control}
            label="Sadece Rehberlik Birimleri Görsün"
            name="counselorVisibility"
            disabled={watchOnlyMeVisibility}
          />

          <ControlledCheckbox
            control={control}
            label="Sadece Ben Görüntüleyim"
            name="creatorOnlyVisibility"
          />
        </div>
      </div>

      {(!isProduction || hasPermission(Operations.GetAllPermissions)) && (
        <ControlledCheckbox
          control={control}
          label="Velilere SMS gitsin mi?"
          name="sendSmsToParents"
        />
      )}
      <CustomButton variant="save" />
    </form>
  );
};

export default TrackingNoteClassesForm;
