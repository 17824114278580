import * as yup from 'yup';

/**
 * Creates a dynamic validation schema helper for max length
 * @param schema - The Yup schema to extract the max value from
 * @param fieldName - The name of the field whose max value is checked
 * @returns The max value or null if not found
 */
export const findMaxCharacterLimit = <T extends yup.AnyObjectSchema>(
  schema: T,
  fieldName: keyof yup.InferType<T>
): number | null => {
  const field = schema.fields[fieldName as string] as
    | yup.StringSchema
    | undefined;

  if (field) {
    const meta = field.describe();
    const maxConstraint = meta.tests.find((test) => test.name === 'max');

    if (maxConstraint) {
      return typeof maxConstraint.params?.max === 'number'
        ? maxConstraint.params.max
        : null;
    }
  }

  return null;
};
