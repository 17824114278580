import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCampusSearchValue } from '@/utils/constants/roles';
import { Operations } from '@/utils/enums/Operations';
import { addToast } from '@/utils/helpers/toast';
import { getAccessToken } from '@/utils/helpers/authHelpers';

const baseUrl = import.meta.env.VITE_API_URL;

interface ResultNode {
  [key: string]: ResultNode | boolean;
}

interface SubmitExportParams {
  endpoint: string;
  body: {
    columns: ResultNode;
    filters: { [key: string]: string };
  };
  permission: Operations;
}
export const fetchExportFields = createAsyncThunk(
  'export/fetchFields',
  async ({
    endpoint,
    permission,
  }: {
    endpoint: string;
    permission: Operations;
  }) => {
    // const campusID = getCampusSearchValue(permission as Operations);
    const fullEndpoint = `${baseUrl}${endpoint}/export/fields`;

    const response = await fetch(fullEndpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        CampusID: permission,
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });

    if (!response.ok) {
      addToast({
        severity: 'error',
        summary: 'Hata',
        detail: 'Dışarı aktarım alanları getirilirken bir hata oluştu.',
      });
    }

    const result = await response.json();
    return result.data;
  }
);

export const submitExport = createAsyncThunk(
  'export/submitExport',
  async ({ endpoint, body, permission }: SubmitExportParams) => {
    const fullEndpoint = `${baseUrl}${endpoint}/export`;
    const response = await fetch(fullEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // CampusID: getCampusSearchValue(permission as Operations),
        campusID: permission,
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      addToast({
        severity: 'error',
        summary: 'Hata',
        detail: 'Dışarı aktarım işlemi sırasında bir hata oluştu.',
      });
    }

    const result = await response.json();
    return result.data;
  }
);

export const downloadFile = createAsyncThunk(
  'export/downloadFile',
  async ({
    documentID,
    isEmailSend = false,
  }: {
    documentID: string;
    isEmailSend?: boolean;
  }) => {
    const fileEndpoint = `${baseUrl}/exported-files/${documentID}${
      isEmailSend ? `?isEmailSend=true` : ''
    }`;
    const response = await fetch(fileEndpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        CampusID: getCampusSearchValue(Operations.GetExportedFile),
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });

    if (!response.ok) {
      throw new Error('Dosya indirilirken bir hata oluştu.');
    }

    const result = await response.json();
    return result.data;
  }
);
