export enum Operations {
  // TODO: Roles without permission to view the homepage (Güvenlik,Yemekhane, Servis vb.)
  GetDashboard = 'get-dashboard',
  GetExportedFile = 'get-exported-file',

  GetAllPermissions = 'get-all-permissions',
  // Organizations - Organizasyonlar
  CreateOrganization = 'create-organization',
  GetEnrichedOrganizations = 'get-enriched-organizations',
  GetOrganization = 'get-organization',
  UpdateOrganization = 'update-organization',
  GetOrganizationRoles = 'get-organization-roles',
  CreateOrganizationRole = 'create-organization-role',
  UpdateOrganizationRole = 'update-organization-role',
  DeleteOrganizationRole = 'delete-organization-role',
  GetOrganizationRolePermissions = 'get-organization-role-permissions',
  UseLoginAs = 'use-login-as',
  AddBulkPermissionsToRoles = 'add-bulk-permissions-to-roles',
  DeleteBulkPermissionsFromRoles = 'delete-bulk-permissions-from-roles',

  // MyOrganization - Organizasyonum
  GetMyOrganization = 'get-my-organization',

  // E12 Employees - E12 Personeller
  CreateE12Employee = 'create-e12-employee',

  // Campuses - Kampüsler
  CreateCampus = 'create-campus',
  CreateClass = 'create-class',
  GetCampus = 'get-campus',
  UpdateCampus = 'update-campus',
  GetClassList = 'get-class-list',
  GetClassListFE = 'get-class-list-fe',
  GetClassListLimited = 'get-class-list-limited',
  GetClassDetail = 'get-class-detail',
  GetCampusDepartments = 'get-campus-departments',
  UpdateClass = 'update-class',
  DeleteClass = 'delete-class',
  GetCampusGrades = 'get-campus-grades',
  GetCampusGradeClasses = 'get-campus-grade-classes',
  GetCampusDepartmentsOverview = 'get-campus-departments-overview',
  GetGradeListOverview = 'get-grade-list-overview',
  GetGradeListOverviewLimited = 'get-grade-list-overview-limited',
  GetGradeListOverviewUnlimited = 'get-grade-list-overview-unlimited',
  GetCampusesOverview = 'get-campuses-overview',
  GetCampusesOverviewFE = 'get-campuses-overview-fe',

  // Specialties - Branşlar
  GetSpecialties = 'get-specialties',
  GetSpecialtiesFE = 'get-specialties-fe',
  CreateSpecialty = 'create-specialty',
  GetSpecialty = 'get-specialty',
  UpdateSpecialty = 'update-specialty',
  DeleteSpecialty = 'delete-specialty',

  // Users
  CreateUser = 'create-user',

  // Students - Öğrenciler
  GetStudentList = 'get-student-list',
  GetStudentListLimited = 'get-student-list-limited',
  GetStudentListFE = 'get-student-list-fe',
  GetStudentByID = 'get-student-by-id', // TODO: unused. Check where to use
  GetStudentPersonalInformation = 'get-student-personal-info',
  GetParentInformation = 'get-parent-information',
  GetStudentRegistrationInfo = 'get-student-registration-information',
  ChangeStudentPassword = 'change-student-password',
  DeleteStudent = 'delete-student',
  ExportStudents = 'export-students',
  UpdateStudent = 'update-student',

  GetLessonTrackingNoteList = 'get-student-tracking-note-list',
  GetLessonTrackingNoteDetail = 'get-student-tracking-note-detail',
  CreateLessonTrackingNote = 'create-student-tracking-note',
  UpdateLessonTrackingNote = 'update-student-tracking-note',
  DeleteLessonTrackingNote = 'delete-student-tracking-note',
  GetStudentTrackingNoteReport = 'get-student-tracking-report',
  CanSeeCounselor = 'can-see-counselor',
  CanSeeManagement = 'can-see-management',
  ExportStudentTrackingNote = 'export-student-tracking-note',
  ExportEmployeeTrackingNote = 'export-employee-tracking-note',
  ExportEmployeeHomework = 'export-employee-homework',

  // Employees - Personeller
  GetEmployeeList = 'get-employee-list',
  GetEmployeePersonalInfo = 'get-employee-personal-info',
  GetEmployeeRegistrationInfo = 'get-employee-registration-information',
  ChangeEmployeePassword = 'change-employee-password',
  DeleteEmployee = 'delete-employee',
  GetContractInformation = 'get-contract-information',
  ExportEmployees = 'export-employees',
  GetEmployeeDetail = 'get-employee-detail',
  UpdateEmployee = 'update-employee',
  GetEmployeeReports = 'get-employee-reports',

  // Curriculums - Müfredatlar
  GetCurriculumList = 'get-curriculum-list',
  GetCurriculumListFE = 'get-curriculum-list-fe',
  CreateCurriculum = 'create-curriculum',
  UpdateCurriculum = 'update-curriculum',
  DeleteCurriculum = 'delete-curriculum',
  GetCurriculum = 'get-curriculum',

  // Lessons - Dersler
  CreateLesson = 'create-lesson',
  GetLessonList = 'get-lesson-list',
  GetLessonListFE = 'get-lesson-list-fe',
  UpdateLesson = 'update-lesson',
  DeleteLesson = 'delete-lesson',
  GetLessonDetail = 'get-lesson-detail',

  // Learning Domains - Öğrenme Alanları
  GetLearningDomainList = 'get-learning-domain-list',
  CreateLearningDomain = 'create-learning-domain',
  UpdateLearningDomain = 'update-learning-domain',
  DeleteLearningDomain = 'delete-learning-domain',
  GetLearningDomain = 'get-learning-domain',

  // Sub Learning Domains - Alt Öğrenme Alanları
  GetSubLearningDomainList = 'get-sub-learning-domain-list',
  CreateSubLearningDomain = 'create-sub-learning-domain',
  UpdateSubLearningDomain = 'update-sub-learning-domain',
  DeleteSubLearningDomain = 'delete-sub-learning-domain',
  GetSubLearningDomain = 'get-sub-learning-domain',

  // Subjects - Konular
  GetSubjectList = 'get-subject-list',
  GetSubjectListFE = 'get-subject-list-fe',
  CreateSubject = 'create-subject',
  UpdateSubject = 'update-subject',
  DeleteSubject = 'delete-subject',
  GetSubject = 'get-subject',

  // Learning Outcomes - Kazanımlar
  GetLearningOutcomeList = 'get-learning-outcome-list',
  GetLearningOutcomeListFE = 'get-learning-outcome-list-fe',
  CreateLearningOutcome = 'create-learning-outcome',
  UpdateLearningOutcome = 'update-learning-outcome',
  DeleteLearningOutcome = 'delete-learning-outcome',
  GetLearningOutcome = 'get-learning-outcome',

  // Sub Learning Outcomes - Alt Kazanımlar
  GetSubLearningOutcomeList = 'get-sub-learning-outcome-list',
  CreateSubLearningOutcome = 'create-sub-learning-outcome',
  UpdateSubLearningOutcome = 'update-sub-learning-outcome',
  DeleteSubLearningOutcome = 'delete-sub-learning-outcome',
  GetSubLearningOutcome = 'get-sub-learning-outcome',

  // Lesson Contents - İçerikler
  CreateLessonContent = 'create-lesson-content',
  GetLessonContents = 'get-lesson-contents',
  UpdateLessonContent = 'update-lesson-content',
  DeleteLessonContent = 'delete-lesson-content',
  GetLessonContent = 'get-lesson-content', // TODO: unsused

  // Annual Plans - Yıllık Planlar
  GetAnnualPlanList = 'get-annual-plan-list',
  GetAnnualPlanListFE = 'get-annual-plan-list-fe',
  CreateAnnualPlan = 'create-annual-plan',
  UpdateAnnualPlan = 'update-annual-plan',
  UpdateCurrentWeekAnnualPlan = 'update-current-week-annual-plan',
  DeleteAnnualPlan = 'delete-annual-plan',
  GetAnnualPlan = 'get-annual-plan',

  // Annual Plan Weeks - Yıllık Plan Haftaları
  GetAnnualPlanWeekList = 'get-annual-plan-week-list',
  CreateAnnualPlanWeeks = 'create-annual-plan-weeks',
  UpdateAnnualPlanWeeks = 'update-annual-plan-weeks',

  // Places - Mekanlar
  GetPlaceList = 'get-place-list',
  CreatePlace = 'create-place',
  UpdatePlace = 'update-place',
  DeletePlace = 'delete-place',
  ExportPlace = 'export-place',
  GetPlace = 'get-place', // TODO: unsused

  // Files
  UploadFile = 'upload-file', // TODO: unsused

  // Unit Timelines - Birim Zaman Çizelgeleri
  GetUnitTimelineList = 'get-unit-timeline-list',
  UpdateUnitTimeline = 'update-unit-timeline',
  DeleteUnitTimeline = 'delete-unit-timeline',
  GetUnitTimeline = 'get-unit-timeline',

  // Academic Years
  GetAcademicYearList = 'get-academic-year-list', // TODO: unused

  // Video Pool - Video Havuzu
  CreateVideo = 'create-video',
  UpdateVideo = 'update-video',
  GetVideoDetail = 'get-video-detail',
  DeleteVideo = 'delete-video',
  GetVideos = 'get-videos',

  // Class Schedules - Şube Ders Programları
  GetClassScheduleList = 'get-class-schedule-list',
  UpdateClassSchedule = 'update-class-schedule',
  DeleteClassSchedule = 'delete-class-schedule',
  GetClassSchedule = 'get-class-schedule',

  // Live Lesson - Canlı Dersler
  CreateLiveLesson = 'create-live-lesson',
  GetLiveLessons = 'get-live-lessons',
  GetLiveLessonDetail = 'get-live-lesson-detail',
  AccessLiveLesson = 'access-live-lesson',
  UpdateLiveLesson = 'update-live-lesson',
  DeleteLiveLesson = 'delete-live-lesson',

  // Attendance - Yoklama
  GetAttendanceList = 'get-attendance-list',
  GetAttendanceListLimited = 'get-attendance-list-limited',
  UpdateAttendance = 'update-attendance',
  GetAttendanceDetail = 'get-attendance-detail',
  GetAttendance = 'get-attendance',

  // Groups - Gruplar
  GetGroupList = 'get-group-list',
  GetGroup = 'get-group',
  CreateGroup = 'create-group',
  UpdateGroup = 'update-group',
  DeleteGroup = 'delete-group',

  // Question Pool - Soru Havuzu
  GetQuestionList = 'get-question-list',
  CreateQuestion = 'create-question',
  UpdateQuestion = 'update-question',
  DeleteQuestion = 'delete-question',
  GetQuestion = 'get-question',

  // Quiz Pool - Quiz Havuzu
  GetQuizPoolList = 'get-quizpool-list',
  CreateQuizPool = 'create-quizpool',
  UpdateQuizPool = 'update-quizpool',
  DeleteQuizPool = 'delete-quizpool',
  GetQuizPool = 'get-quizpool',

  // Homework - Ödevler
  CreateHomework = 'create-homework',
  GetHomework = 'get-homework',
  GetHomeworkList = 'get-homework-list',
  UpdateHomework = 'update-homework',
  DeleteHomework = 'delete-homework',
  GetHomeworkReport = 'get-homework-report',
  ExportHomework = 'export-homework',

  /* ACCOUNTING - MUHASEBE */

  // Vault Cash - Kasa
  CreateAccVaultCash = 'create-acc-vault-cash',
  UpdateAccVaultCash = 'update-acc-vault-cash',
  DeleteAccVaultCash = 'delete-acc-vault-cash',
  GetAccVaultCash = 'get-acc-vault-cash', // ByID for VaultCashesForm
  GetAccVaultCashDetail = 'get-acc-vault-cash-detail',
  GetAccVaultCashList = 'get-acc-vault-cash-list', // Dropdown
  GetAccVaultCashOverview = 'get-acc-vault-cash-overview',

  // Vault Cash Expense - Kasa Gider
  CreateAccEmployeeExpense = 'create-acc-employee-expense',
  CreateAccOtherExpense = 'create-acc-other-expense',
  UpdateAccExpense = 'update-acc-expense',
  DeleteAccExpense = 'delete-acc-expense',
  GetAccExpense = 'get-acc-expense',
  GetAccExpenseOverview = 'get-acc-expense-overview',

  // Payment Plan - Öğrenci Ödeme
  CreateAccPaymentPlan = 'create-acc-payment-plan',
  UpdateAccPaymentPlan = 'update-acc-payment-plan',
  DeleteAccPaymentPlan = 'delete-acc-payment-plan',
  GetAccPaymentPlan = 'get-acc-payment-plan',
  GetAccPaymentPlanList = 'get-acc-payment-plan-list',
  GetAccPaymentPlanOverview = 'get-acc-payment-plan-overview',
  GetAccPaymentPlanDetail = 'get-acc-payment-plan-detail',
  GetAccPaymentPlanInfo = 'get-acc-payment-plan-info',

  // Supplier - Tedarikçi
  CreateAccSupplier = 'create-acc-supplier',
  UpdateAccSupplier = 'update-acc-supplier',
  DeleteAccSupplier = 'delete-acc-supplier',
  GetAccSupplierList = 'get-acc-supplier-list',
  GetAccSupplier = 'get-acc-supplier',
  GetAccSupplierOverview = 'get-acc-supplier-overview',

  // Collection - Tahsilat
  CreateAccCollection = 'create-acc-collection',
  UpdateAccCollection = 'update-acc-collection',
  GetAccCollection = 'get-acc-collection',
  GetAccCollectionOverview = 'get-acc-collection-overview',

  /***/

  // Meeting - Toplantılar (Mock)
  GetMeetingList = 'get-meeting-list',
  UpdateMeeting = 'update-meeting',
  DeleteMeeting = 'delete-meeting',
  GetMeeting = 'get-meeting',
  CreateMeeting = 'create-meeting',

  // Society - Zümre (Mock)
  GetSocietyList = 'get-society-list',
  CreateSociety = 'create-society',
  UpdateSociety = 'update-society',
  DeleteSociety = 'delete-society',
  GetSocietyDetail = 'get-society-detail',
  CreateSocietyDemand = 'create-society-demand',
  UpdateSocietyDemand = 'update-society-demand',

  // Demands - Talepler
  GetDemandList = 'get-demand-list',
  GetDemandListUnlimited = 'get-demand-detail-list-unlimited',
  CreateDemand = 'create-demand',
  UpdateDemand = 'update-demand',
  UpdateDemandStatus = 'update-demand-status',
  DeleteDemand = 'delete-demand',

  GetDemandDetail = 'get-demand-detail',
  GetDemandDetailList = 'get-demand-detail-list',
  GetDemandDetailListUnlimited = 'get-demand-detail-list-unlimited',
  CreateDemandDetail = 'create-demand-detail',
  UpdateDemandDetail = 'update-demand-detail',
  DeleteDemandDetail = 'delete-demand-detail',

  CanMakeDemandDesicion = 'can-make-demand-decision',
  CanDeleteDemand = 'can-delete-demand',
  CanUpdateDemand = 'can-update-demand',
  CanDeleteDemandDetail = 'can-delete-demand-detail',
  CanUpdateDemandDetail = 'can-update-demand-detail',

  // Study - Etüt (Mock)
  GetStudyList = 'get-study-list',
  CreateStudy = 'create-study',
  UpdateStudy = 'update-study',
  DeleteStudy = 'delete-study',
  GetStudyDetail = 'get-study-detail',
  GetStudyAttendance = 'get-study-attendance',
  UpdateStudyAttendance = 'update-study-attendance',

  // Infirmary - Revir (Mock)
  GetInfirmaryList = 'get-infirmary-list',
  CreateInfirmary = 'create-infirmary',
  GetInfirmaryDetail = 'get-infirmary-detail',
  UpdateInfirmary = 'update-infirmary',
  DeleteInfirmary = 'delete-infirmary',

  // Report And Activity - Öğrenci Rapor ve Aktivite (Mock)
  GetStudentReportAndActivity = 'get-student-report-and-activity',
  GetStudentInteractiveContentList = 'get-student-video-activity-list',
  GetStudentVideoActivityList = 'get-student-video-activity-list',
  GetStudentQuizActivityList = 'get-student-quiz-activity-list',
  GetStudentQuestionActivityList = 'get-student-question-activity-list',

  // Template - Şablonlar
  GetTemplateList = 'get-template-list',
  CreateTemplate = 'create-template',
  GetTemplate = 'get-template',
  UpdateTemplate = 'update-template',
  DeleteTemplate = 'delete-template',

  // AI Configs - AI Konfigürasyonları
  GetAIConfigList = 'get-ai-config-list',
  CreateAIConfig = 'create-ai-config',
  GetAIConfig = 'get-ai-config',
  UpdateAIConfig = 'update-ai-config',
  DeleteAIConfig = 'delete-ai-config',

  // Announcement - Duyurular
  CreateCampusAnnouncement = 'create-campus-announcement',
  CreateDepartmentAnnouncement = 'create-department-announcement',
  CreateGradeAnnouncement = 'create-grade-announcement',
  CreateClassAnnouncement = 'create-class-announcement',
  CreateGroupAnnouncement = 'create-group-announcement',
  CreateSocietyAnnouncement = 'create-society-announcement',

  GetAnnouncementDetail = 'get-announcement-detail',
  UpdateAnnouncement = 'update-announcement',
  DeleteAnnouncement = 'delete-announcement',
  GetAnnouncementList = 'get-announcement-list',
  GetAnnouncementListLimited = 'get-announcement-list-limited',
  GetAnnouncementListUnlimited = 'get-announcement-list-unlimited',
  CanSeeCounselorAnnouncement = 'can-see-counselor-announcement',
  CanSeeManagementAnnouncement = 'can-see-management-announcement',

  GetMyAnnouncementList = 'get-my-announcement-list',
  GetMyAnnouncementDetail = 'get-my-announcement-detail',
  UpdateMyAnnouncement = 'update-my-announcement',
  DeleteMyAnnouncement = 'delete-my-announcement',
  CanSeeMyCounselorAnnouncement = 'can-see-my-counselor-announcement',
  CanSeeMyManagementAnnouncement = 'can-see-my-management-announcement',

  // Audit - Denetim Kayıtları
  GetAuiditLogList = 'get-audit-log-list',

  // SolverQuestions - Soru Çözüm
  GetSolverQuestionList = 'get-solver-question-list',
  GetSolverQuestionDetail = 'get-solver-question-detail',
  AnswerSolverQuestion = 'answer-solver-question',
  UpdateSolverAnswer = 'update-solver-answer',

  // Chatbot
  UseChatbot = 'use-chatbot',

  // Contracts - Sözleşmeler
  GetContractList = 'get-contract-list',
  GetContractListLimited = 'get-contract-list-limited',
  GetContract = 'get-contract',
  CreateContract = 'create-contract',
  UpdateContract = 'update-contract',
  DeleteContract = 'delete-contract',

  // Notifications Settings - Bildirim Ayarları
  // SMS Permissions
  SendCustomSMSToCampus = 'send-custom-sms-to-campus',
  SendCustomSMSToDepartment = 'send-custom-sms-to-department',
  SendCustomSMSToGradeClass = 'send-custom-sms-to-grade-class',
  SendStudentTrackingNotesSMS = 'send-student-tracking-notes-sms',
  SendClassTrackingNotesSMS = 'send-class-tracking-notes-sms',
  SendAttendanceSMS = 'send-attendance-sms',
  SendInfirmaryNoteSMS = 'send-infirmary-note-sms',
  SendStudyNoteSMS = 'send-study-note-sms',
  SendStudentParentPasswordChangeSMS = 'send-student-parent-password-change-sms',
  SendAccountingParentSMS = 'send-accounting-parent-sms',
  SendHomeworkSMS = 'send-homework-sms',
  SendLiveLessonSMS = 'send-live-lesson-sms',
  SendCampusAnnouncementSMS = 'send-campus-announcement-sms',
  SendDepartmentAnnouncementSMS = 'send-department-announcement-sms',
  SendClassGradeAnnouncementSMS = 'send-class-grade-announcement-sms',

  // Email Permissions
  SendCustomEmailToCampus = 'send-custom-email-to-campus',
  SendCustomEmailToDepartment = 'send-custom-email-to-department',
  SendCustomEmailToGradeClass = 'send-custom-email-to-grade-class',
  SendStudentTrackingNotesEmail = 'send-student-tracking-notes-email',
  SendClassTrackingNotesEmail = 'send-class-tracking-notes-email',
  SendAttendanceEmail = 'send-attendance-email',
  SendInfirmaryNoteEmail = 'send-infirmary-note-email',
  SendStudyNoteEmail = 'send-study-note-email',
  SendStudentParentPasswordChangeEmail = 'send-student-parent-password-change-email',
  SendAccountingParentEmail = 'send-accounting-parent-email',
  SendHomeworkEmail = 'send-homework-email',
  SendLiveLessonEmail = 'send-live-lesson-email',
  SendCampusAnnouncementEmail = 'send-campus-announcement-email',
  SendDepartmentAnnouncementEmail = 'send-department-announcement-email',
  SendClassGradeAnnouncementEmail = 'send-class-grade-announcement-email',

  // SMS - Email Notification Configs
  GetSmsNotificationConfig = 'get-sms-notification-config',
  GetEmailNotificationConfig = 'get-email-notification-config',
  CreateSmsNotificationConfig = 'create-sms-notification-config',
  CreateEmailNotificationConfig = 'create-email-notification-config',
  GetSmsQuota = 'get-sms-quota',
  UpdateSmsQuota = 'update-sms-quota',

  // Landing Page
  GetApplicationList = 'get-lp-application-list',
  GetApplication = 'get-lp-application-detail',
  GetNewsletterList = 'get-lp-newsletter-list',
}
