import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
// import * as Sentry from '@sentry/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConfirmDialog } from 'primereact/confirmdialog';
import AppRouter from './routes';
import { getAccessTokenByApi } from './services/authentication/accessToken';
import { navigateToSignin } from './utils/helpers/navigate';
import { isMobile } from './utils/helpers/common';
import { parseAuthToken } from './utils/helpers/token';
import ExportModal from '@/components/ExportComponent/ExportModal';
import { isSafari } from './utils/constants/browser';
import {
  getAccessToken,
  updateAccessToken,
  updateCurrentCampusID,
} from '@/utils/helpers/authHelpers';
import { initUserPermissions } from './utils/helpers/userPermission';

const AppContainer: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true);

  // const setSentryContext = () => {
  //   const parsedAuthToken = parseAuthToken(getAccessToken());

  //   Sentry.setContext('userInfo', {
  //     organizationID: parsedAuthToken.organizationID,
  //     roles: parsedAuthToken.roles,
  //     managementRoles: parsedAuthToken.managementRoles,
  //     selectedCampus: localStorage.getItem('E12_SELECTED_CAMPUS') || '',
  //   });
  // };

  const fetchAccessToken = () => {
    if (!getAccessToken()) {
      getAccessTokenByApi()
        .then((resp) => {
          if (resp.data.data.accessToken) {
            updateAccessToken(resp.data.data.accessToken);
            initUserPermissions(resp.data.data.permissions);

            const authToken = parseAuthToken(resp.data.data.accessToken);

            if (!authToken.currentCampusID) {
              navigateToSignin();
            }

            updateCurrentCampusID(authToken.currentCampusID);

            setLoading(false);
          }
        })
        .catch(() => {
          navigateToSignin();
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchAccessToken();
    // TODO: open this to init sentry
    // setSentryContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Buraya full page loader gelecek. -Berk
  if (loading) return null;

  return <div>{children}</div>;
};

// index asset error fix
window.addEventListener('vite:preloadError', () => {
  if (!isMobile && !isSafari) {
    window.location.reload();
  }
});

function App() {
  // eslint-disable-next-line no-console
  // console.log('mode :>> ', import.meta.env.VITE_ENV);

  return (
    <AppContainer>
      <ToastContainer />
      <AppRouter />
      <ConfirmDialog />
      <ExportModal />
    </AppContainer>
  );
}

export default App;
