/* eslint-disable import/no-mutable-exports */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { Nullable } from '@/utils/globalTypes';
import { getCampusId } from '@/utils/helpers/campus';
import { getAccessTokenByApi } from './authentication/accessToken';
import { initUserPermissions } from '@/utils/helpers/userPermission';
import { parseAuthToken } from '@/utils/helpers/token';
import {
  getAccessToken,
  updateAccessToken,
  updateCurrentCampusID,
} from '@/utils/helpers/authHelpers';
import { navigateToSignin } from '@/utils/helpers/navigate';

interface IErrorResponse {
  message?: string;
  validations?: {
    key: string;
    message: string;
  }[];
}

const axiosInstance = axios.create({
  // Axios configuration
  baseURL: import.meta.env.VITE_API_URL,
  validateStatus(status) {
    return status >= 200 && status <= 300;
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    if (config.headers.isPublic) {
      delete config.headers.isPublic;
      return config;
    }

    if (!getAccessToken() && !config.headers.isPublic) {
      const respToken = await getAccessTokenByApi();
      if (respToken.data.data.accessToken) {
        const data = parseAuthToken(respToken.data.data.accessToken);

        if (data.currentCampusID) {
          updateCurrentCampusID(data.currentCampusID);
        }

        updateAccessToken(respToken.data.data.accessToken);
        initUserPermissions(respToken.data.data.permissions);

        if (!data.currentCampusID) {
          navigateToSignin();
        }
      } else {
        navigateToSignin();
      }
    }

    config.headers.Authorization = `Bearer ${getAccessToken()}`;
    config.headers.campusID = config.headers.CampusID || getCampusId();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config } = error;
    const originalRequest = config;
    const status = error?.response?.status;

    // When got 401 error, the access token should be refreshed.
    if (status === 401) {
      try {
        const resp = await getAccessTokenByApi();
        if (resp?.status === 201) {
          updateAccessToken(resp.data.data.accessToken);
          initUserPermissions(resp.data.data.permissions);
          originalRequest.headers.Authorization = `Bearer ${resp.data.data.accessToken}`;
          // eslint-disable-next-line @typescript-eslint/return-await
          return axiosInstance(originalRequest);
        }
      } catch (_error) {
        // If access token is invalid in the second time, redirect to the main page for login.
        navigateToSignin();
        // eslint-disable-next-line consistent-return
        return;
      }
    }
    return Promise.reject(error);
  }
);

export const axiosBaseQuery =
  (
    config?: Nullable<AxiosRequestConfig> & {
      prefix?: string;
    }
  ): BaseQueryFn<AxiosRequestConfig, unknown, AxiosError> =>
  async (props) => {
    const {
      url,
      method = 'GET',
      data,
      params,
      headers,
      withCredentials,
      onUploadProgress,
    } = props;
    try {
      const transformedUrl = config?.prefix ? `${config.prefix}${url}` : url;
      const result = await axiosInstance({
        ...config,
        url: transformedUrl,
        method,
        data,
        params,
        headers,
        withCredentials,
        onUploadProgress,
      });
      return {
        data: result.data.data,
        error: result.data.error,
      };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      const error = {
        ...(err.response?.data as { data: unknown }),
        status: err.response?.status,
      } as {
        data: unknown;
        status: number;
        error: IErrorResponse;
      };
      return {
        data: null,
        error: error.error,
      };
    }
  };
