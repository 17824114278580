import { store, RootState } from '@/store';
import {
  removeAccessToken,
  saveAccessToken,
  setCurrentCampusID,
} from '@/store/common/commonSlice';

// SETTERS
export const updateAccessToken = (token: string) => {
  store.dispatch(saveAccessToken(token));
};

export const clearAccessToken = () => {
  store.dispatch(removeAccessToken());
};

export const updateCurrentCampusID = (campusID: string) => {
  store.dispatch(setCurrentCampusID(campusID));
};

// GETTERS
export const getAccessToken = (): string => {
  const state: RootState = store.getState();
  return state.common.accessToken;
};

export const getCurrentCampusID = (): string => {
  const state: RootState = store.getState();
  return state.common.currentCampusID;
};
