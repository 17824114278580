import { TreeCheckboxSelectionKeys } from 'primereact/tree';
import { TreeNode } from 'primereact/treenode';
import dayjs from 'dayjs';
import {
  getCampusSearchValue,
  showAllCampusesOption,
} from '@/utils/constants/roles';
import { Operations } from '@/utils/enums/Operations';
import { hasPermission } from '@/utils/helpers/userPermission';

export const getQueryParams = (search: string) => {
  const params = new URLSearchParams(search);
  const filters: { [key: string]: string | boolean } = {};

  const parseValue = (value: string): string | boolean => {
    if (value === 'true') return true;
    if (value === 'false') return false;
    return value;
  };

  params.forEach((value, key) => {
    filters[key] = parseValue(value);
  });

  delete filters.page;
  delete filters.perPage;

  return filters;
};

export const filterDictionary: Record<string, string> = {
  q: 'İsim',
  isActive: 'Aktif mi?',
  isClassroom: 'Derslik mi?',
  departmentName: 'Departman Adı',
  campusName: 'Kampüs Adı',
  identityNumber: 'T.C. Kimlik Numarası',
  departmentID: 'Departman ID',
  gradeName: 'Sınıf',
  className: 'Şube',
  roleName: 'Rol Adı',
  specialtyName: 'Branş',
  lessonName: 'Ders Adı',
  dateName: 'Tarih Aralığı',
  studentVisibility: 'Öğrenci Görsün',
  parentVisibility: 'Veli Görsün',
  managementVisibility: 'Yöneticim Görsün',
  counselorVisibility: 'Sadece Rehberlik Birimleri Görsün',
  creatorOnlyVisibility: 'Oluşturan Görsün',
  fromDate: 'Başlangıç Tarihi',
  toDate: 'Bitiş Tarihi',
  studentName: 'Öğrenci Adı',
};

export const bannedKeys = ['ID', 'dateRange', 'role', 'createdBy'];

export const filterValueDictionary: Record<string, string> = {
  true: 'Evet',
  false: 'Hayır',
};

export const formatFilterValue = (key: string, value: unknown): string => {
  const validKey = key.includes('fromDate') || key.includes('toDate');

  if (validKey && typeof value === 'string') {
    return dayjs(value).format('DD.MM.YYYY HH:mm');
  }

  if (value !== null && value !== undefined) {
    const stringValue = value.toString();
    return filterValueDictionary[stringValue] || stringValue;
  }

  return '';
};

interface ResultNode {
  [key: string]: ResultNode | boolean;
}

export const convertSelectedKeysToBody = (sKeys: TreeCheckboxSelectionKeys) => {
  const result: ResultNode = {};

  Object.keys(sKeys).forEach((key) => {
    const keys = key.split('.');
    let current = result;

    keys.forEach((k, index) => {
      const isLast = index === keys.length - 1;

      if (isLast) {
        if (sKeys[key].checked) {
          current[k] = true;
        }
      } else if (!current[k] || typeof current[k] !== 'object') {
        current[k] = {};
      }

      current = current[k] as ResultNode;
    });
  });

  return result;
};

interface DataNode {
  name: string;
  key: string;
  columns?: DataNode[];
}

export const transformDataToTreeNodes = (
  data: DataNode[],
  parentKey = ''
): TreeNode[] => {
  return data.map((item) => {
    const node: TreeNode = {
      key: parentKey ? `${parentKey}.${item.name}` : item.name,
      label: item.key,
      style: parentKey ? { marginLeft: '10px' } : {},
    };

    if (item.columns) {
      node.children = transformDataToTreeNodes(
        item.columns,
        node.key as string
      );
    }

    return node;
  });
};

export const getExportRoleControl = (
  operation: Operations,
  campusID: string
): string => {
  if (campusID === undefined && showAllCampusesOption(operation)) {
    return 'all';
  }
  if (campusID !== undefined && hasPermission(operation)) {
    return campusID;
  }
  return getCampusSearchValue(operation);
};
