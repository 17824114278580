import { Checkbox, CheckboxProps } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';
import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

interface IProps<T extends FieldValues> extends Omit<CheckboxProps, 'checked'> {
  control: Control<T>;
  label: string;
  name: Path<T>;
  wrapperClassName?: string;
}

const ControlledCheckbox = <T extends FieldValues>({
  control,
  label,
  name,
  wrapperClassName,
  ...inputProps
}: IProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState, formState }) => (
        <span
          className={classNames(
            'flex items-center space-x-2',
            wrapperClassName
          )}
        >
          <Checkbox
            inputId={field.name}
            {...field}
            className={classNames({
              'p-invalid mr-1': fieldState.error,
              'opacity-50 cursor-not-allowed': inputProps.disabled,
            })}
            onChange={(e) => field.onChange(e.checked)}
            checked={field.value}
            {...inputProps}
          />
          {label && (
            <label
              htmlFor={field.name}
              className={classNames({
                'cursor-pointer': true,
                'p-error': formState.errors.checked,
                'opacity-50 cursor-not-allowed': inputProps.disabled,
              })}
            >
              {label}
            </label>
          )}
          {fieldState.error ? (
            <small className="p-error">
              {fieldState.error?.message as string}
            </small>
          ) : (
            <small className="p-error">&nbsp;</small>
          )}
        </span>
      )}
    />
  );
};

export default ControlledCheckbox;
