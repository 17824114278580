import React from 'react';
import { hasPermission } from '@/utils/helpers/userPermission';
import { Operations } from '@/utils/enums/Operations';
import { getCampusId } from '@/utils/helpers/campus';
import TrackingNoteClassesForm from '@/components/TrackingNote/TrackingNoteClassesForm';

/** Main */

const SignIn = React.lazy(() => import('@/pages/SignIn'));
const Layout = React.lazy(() => import('@/components/Layout'));
const Home = React.lazy(() => import('@/pages/Home'));
const NotFound = React.lazy(() => import('@/pages/NotFound'));
const SelectCampus = React.lazy(() => import('@/pages/SelectCampus'));
const ContractPreview = React.lazy(
  () => import('@/pages/Contracts/ContractPreview')
);
const PaymentPlanPreview = React.lazy(
  () => import('@/pages/StudentPayments/PaymentPlanPreview')
);

export const mainRoutes = [
  { path: '*', element: <NotFound /> },
  {
    path: '/signin',
    element: (
      // <GoogleReCaptchaProvider
      //   reCaptchaKey={import.meta.env.VITE_RECAPTCHA_CLIENT_KEY}
      // >
      <SignIn />
      // </GoogleReCaptchaProvider>
    ),
    text: 'sidebar.links.home',
    isOnSidebar: false,
    icon: 'pi pi-home',
  },
  {
    path: '/select-campus',
    element: <SelectCampus />,
    handle: {
      title: 'Kampüs Seç',
      condition: Boolean(!getCampusId()),
      redirectTo: '/',
    },
    isOnSidebar: false,
  },
  {
    path: 'contract-preview/:contractID/students/:studentID',
    element: <ContractPreview />,
  },
  {
    path: 'contract-preview/:contractID',
    element: <ContractPreview />,
  },
  {
    path: '/payment-plan/:id/preview',
    element: <PaymentPlanPreview />,
  },
];

/* Internal Admin Pages */

const Organizations = React.lazy(() => import('@/pages/Organizations'));
const SetupOrganization = React.lazy(
  () => import('@/pages/Organizations/Setup')
);
const Roles = React.lazy(() => import('@/pages/Organizations/Roles'));
const Permissions = React.lazy(
  () => import('@/pages/Organizations/Roles/Permissions')
);
const MultiplePermission = React.lazy(
  () => import('@/pages/Organizations/Roles/MultiplePermission')
);

const TemplateForm = React.lazy(() => import('@/pages/Templates/TemplateForm'));

const AIConfigs = React.lazy(() => import('@/pages/AIConfigs'));
const AIConfigForm = React.lazy(() => import('@/pages/AIConfigs/AIConfigForm'));

const MyProfile = React.lazy(() => import('@/pages/MyProfile'));
const MyOrganization = React.lazy(() => import('@/pages/MyOrganization'));

const E12EmployeeForm = React.lazy(
  () => import('@/pages/E12Employee/E12EmployeeForm')
);
const Audits = React.lazy(() => import('@/pages/Audits'));
const Chatbot = React.lazy(() => import('@/pages/Chatbot'));

const Curriculum = React.lazy(() => import('@/pages/Curriculum'));
const Specialties = React.lazy(() => import('@/pages/Specialties'));
const Classes = React.lazy(() => import('@/pages/Classes'));
const Grades = React.lazy(() => import('@/pages/Grades'));
const Lessons = React.lazy(() => import('@/pages/Lessons'));
const LessonForm = React.lazy(() => import('@/pages/Lessons/LessonForm'));
const LearningDomain = React.lazy(() => import('@/pages/LearningDomain'));
const LearningDomainForm = React.lazy(
  () => import('@/pages/LearningDomain/LearningDomainForm')
);
const SubLearningDomain = React.lazy(() => import('@/pages/SubLearningDomain'));
const SubLearningDomainForm = React.lazy(
  () => import('@/pages/SubLearningDomain/SubLearningDomainForm')
);
const Subject = React.lazy(() => import('@/pages/Subject'));
const SubjectForm = React.lazy(() => import('@/pages/Subject/SubjectForm'));

const LearningOutcomes = React.lazy(() => import('@/pages/LearningOutcomes'));
const LearningOutcomeForm = React.lazy(
  () => import('@/pages/LearningOutcomes/LearningOutcomeForm')
);
const SubLearningOutcomes = React.lazy(
  () => import('@/pages/SubLearningOutcomes')
);
const SubLearningOutcomeForm = React.lazy(
  () => import('@/pages/SubLearningOutcomes/SubLearningOutcomeForm')
);

const UnitTimelines = React.lazy(() => import('@/pages/UnitTimelines'));
const CreateUnitTimeline = React.lazy(
  () => import('@/pages/UnitTimelines/CreateUnitTimeline')
);
const ClassSchedules = React.lazy(() => import('@/pages/ClassSchedules'));
const CreateClassSchedule = React.lazy(
  () => import('@/pages/ClassSchedules/ClassScheduleForm')
);

/** Groups */
const Groups = React.lazy(() => import('@/pages/Groups'));
const GroupForm = React.lazy(() => import('@/pages/Groups/GroupForm'));
const GroupMeetingForm = React.lazy(
  () => import('@/pages/Groups/components/GroupMeetingForm')
);
const GroupDemandForm = React.lazy(
  () => import('@/pages/Groups/components/GroupDemandForm')
);

const Societies = React.lazy(() => import('@/pages/Societies'));
const SocietyForm = React.lazy(() => import('@/pages/Societies/SocietyForm'));
const SocietyMeetingForm = React.lazy(
  () => import('@/pages/Societies/components/SocietyMeetingForm')
);

/** SolverQuestions */

const SolverQuestions = React.lazy(() => import('@/pages/SolverQuestions'));
const SolverQuestionForm = React.lazy(
  () => import('@/pages/SolverQuestions/SolverQuestionForm')
);

/** Campus */
const Campuses = React.lazy(() => import('@/pages/Campuses'));
const Departments = React.lazy(() => import('@/pages/Departments'));
const Demands = React.lazy(() => import('../pages/Demands'));
const Announcements = React.lazy(() => import('@/pages/Announcements'));
const AnnouncementForm = React.lazy(
  () => import('@/pages/Announcements/AnnouncementForm')
);
const MyAnnouncementForm = React.lazy(
  () => import('@/pages/MyAnnouncements/MyAnnouncementForm')
);

const MyAnnouncements = React.lazy(
  () => import('@/pages/MyAnnouncements/index')
);

const NotificationSettings = React.lazy(() => import('@/pages/Notifications'));

/** User */
const Employee = React.lazy(() => import('@/pages/Employee'));
const EmployeeForm = React.lazy(() => import('@/pages/Employee/EmployeeForm'));
const EmployeeSchedule = React.lazy(
  () => import('@/pages/Employee/components/EmployeeSchedule')
);
const EmployeeReports = React.lazy(
  () => import('@/pages/Employee/EmployeeReports')
);

const Students = React.lazy(() => import('@/pages/Students'));
const StudentForm = React.lazy(() => import('@/pages/Students/StudentForm'));
const TrackingNote = React.lazy(() => import('@/components/TrackingNote'));
const TrackingNoteForm = React.lazy(
  () => import('@/components/TrackingNote/TrackingNoteForm')
);
/** Contents */
const LessonContents = React.lazy(() => import('@/pages/LessonContents'));
const LessonContentForm = React.lazy(
  () => import('@/pages/LessonContents/LessonContentForm')
);
const LessonContentResources = React.lazy(
  () => import('@/pages/LessonContents/LessonContentResources')
);
const Video = React.lazy(
  () =>
    import(
      '@/pages/LessonContents/LessonContentResources/modules/Video.resources'
    )
);
const PdfResources = React.lazy(
  () =>
    import(
      '@/pages/LessonContents/LessonContentResources/modules/Pdf.resources'
    )
);
const InteractiveContents = React.lazy(
  () =>
    import(
      '@/pages/LessonContents/LessonContentResources/modules/Interactive.resources'
    )
);

/** Annual Plans */

const AnnualPlans = React.lazy(() => import('@/pages/AnnualPlans'));
const AnnualPlanForm = React.lazy(
  () => import('@/pages/AnnualPlans/AnnualPlanForm')
);
const AnnualPlanWeekly = React.lazy(
  () => import('@/pages/AnnualPlans/AnnualPlanWeekly')
);
const AnnualPlanOverview = React.lazy(
  () => import('@/pages/AnnualPlans/AnnualPlanOveriew')
);
const AnnualPlanAssignForm = React.lazy(
  () => import('@/pages/AnnualPlans/AnnualPlanAssignForm')
);

/** Places */

const Places = React.lazy(() => import('@/pages/Places'));
const PlaceForm = React.lazy(() => import('@/pages/Places/PlaceForm'));

/** Video Pool */
const VideoPool = React.lazy(() => import('@/pages/VideoPool'));
const VideoPoolForm = React.lazy(
  () => import('@/pages/VideoPool/VideoPoolForm')
);

/** Canlı Ders */
const LiveLesson = React.lazy(() => import('@/pages/LiveLesson'));
const LiveLessonForm = React.lazy(
  () => import('@/pages/LiveLesson/LiveLessonForm')
);

/** Attendance */
const Attendance = React.lazy(() => import('@/pages/Attendance'));
const TakeAttendance = React.lazy(
  () => import('@/pages/Attendance/TakeAttendance')
);
const AttendanceDetail = React.lazy(
  () => import('@/pages/Attendance/AttendanceDetail')
);

/** Meetings */
const Meetings = React.lazy(() => import('@/pages/Meetings'));

/** Homework */
const Homework = React.lazy(() => import('@/pages/Homework'));
const HomeworkFormMenu = React.lazy(
  () => import('@/pages/Homework/HomeworkForm')
);
const HomeworkAssignments = React.lazy(
  () => import('@/pages/Homework/HomeworkAssignments')
);

const HomeworkReport = React.lazy(
  () => import('@/pages/Homework/HomeworkReport')
);

const HomeworkReportList = React.lazy(
  () => import('@/pages/Homework/HomeworkReport/components/HomeworkReportList')
);

const HomeworkReportChart = React.lazy(
  () => import('@/pages/Homework/HomeworkReport/components/HomeworkReportChart')
);

const HomeworkComparativeReport = React.lazy(
  () =>
    import(
      '@/pages/Homework/HomeworkReport/components/HomeworkComparativeReport'
    )
);

const HomeworkForm = React.lazy(
  () => import('@/pages/Homework/HomeworkForm/components/HomeworkManuelForm')
);
const HomeworkE12Form = React.lazy(
  () => import('@/pages/Homework/HomeworkForm/components/HomeworkE12Form')
);

/** Preaccounting */
const StudentPayments = React.lazy(() => import('@/pages/StudentPayments'));
const StudentPaymentsForm = React.lazy(
  () => import('@/pages/StudentPayments/StudentPaymentForm')
);
const VaultCashes = React.lazy(() => import('@/pages/VaultCashes'));
const VaultCashesForm = React.lazy(
  () => import('@/pages/VaultCashes/VaultCashesForm')
);
const VaultCashesDetail = React.lazy(
  () => import('@/pages/VaultCashes/components/VaultCashesDetail')
);

const IncomeList = React.lazy(
  () =>
    import(
      '@/pages/VaultCashes/components/VaultCashesDetail/components/IncomeList'
    )
);
const ExpenseList = React.lazy(
  () =>
    import(
      '@/pages/VaultCashes/components/VaultCashesDetail/components/ExpenseList'
    )
);
const NetProfit = React.lazy(
  () =>
    import(
      '@/pages/VaultCashes/components/VaultCashesDetail/components/NetProfit'
    )
);

const Expenses = React.lazy(() => import('@/pages/Expenses'));
const ExpenseForm = React.lazy(
  () => import('@/pages/Expenses/ExpenseForm/index')
);

const Suppliers = React.lazy(() => import('@/pages/Suppliers'));
const SupplierForm = React.lazy(() => import('@/pages/Suppliers/SupplierForm'));

const StudentPaymentCollection = React.lazy(
  () => import('@/pages/StudentPaymentCollection')
);

/* Question Pool */
const QuestionPool = React.lazy(() => import('@/pages/QuestionPool'));
const QuestionPoolForm = React.lazy(
  () => import('@/pages/QuestionPool/QuestionPoolForm')
);

/* Quiz Pool */
const QuizPool = React.lazy(() => import('@/pages/QuizPool'));
const QuizPoolForm = React.lazy(() => import('@/pages/QuizPool/QuizPoolForm'));

/* Study */
const Study = React.lazy(() => import('@/pages/Study'));
const StudyForm = React.lazy(() => import('@/pages/Study/StudyForm'));
const StudyAttendance = React.lazy(
  () => import('@/pages/Study/StudyAttendance')
);

/* Infirmary */
const Infirmary = React.lazy(() => import('@/pages/Infirmary'));
const InfirmaryForm = React.lazy(
  () => import('@/pages/Infirmary/InfirmaryForm')
);
const InfirmaryList = React.lazy(
  () => import('@/pages/Infirmary/InfirmaryList')
);

/* Student Report and Activity */
const StudentReportAndActivity = React.lazy(
  () => import('@/pages/StudentReportAndActivity')
);
const StudentActivityDashboard = React.lazy(
  () =>
    import(
      '@/pages/StudentReportAndActivity/components/StudentActivityDashboard'
    )
);

/** Contracts - Sözleşmeler */
const Contracts = React.lazy(() => import('@/pages/Contracts'));
const ContractForm = React.lazy(() => import('@/pages/Contracts/ContractForm'));

/** Landing Page */
const LandingPage = React.lazy(() => import('@/pages/LandingPage'));

export const isProduction = import.meta.env.VITE_ENV === 'prod';

export const sidebarRoutes = [
  {
    element: (
      <Layout>
        <Home />
      </Layout>
    ),
    path: '/',
    icon: 'icon-[material-symbols--home-outline-rounded] size-6',
    isOnSidebar: () => true,
    text: 'sidebar.links.home',
  },
  {
    element: <Layout />,
    text: 'Yönetici İşlemleri',
    children: [
      {
        path: 'profile',
        text: 'Profilim',
        isOnSidebar: () => false,
        icon: 'icon-[material-symbols--person-outline-rounded] size-6',
        handle: {
          title: 'Profilim',
          condition: () => getCampusId(),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <MyProfile />,
          },
        ],
      },
      {
        path: 'organizations',
        text: 'Organizasyonlar',
        isOnSidebar: () => hasPermission(Operations.GetOrganization),
        icon: 'icon-[material-symbols--lan-outline-rounded] size-6',
        handle: {
          title: 'Organizasyon',
          condition: () => hasPermission(Operations.GetOrganization),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <Organizations />,
          },
          {
            path: 'create',
            element: <SetupOrganization />,
            handle: {
              title: 'Organizasyon Oluştur',
              condition: () => hasPermission(Operations.CreateOrganization),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateOrganization),
          },
          {
            path: ':id/update',
            element: <SetupOrganization />,
            handle: {
              title: 'Organizasyon Güncelle',
              condition: () => hasPermission(Operations.UpdateOrganization),
              redirectTo: '/not-found',
            },
          },
          {
            path: 'roles/perms',
            element: <MultiplePermission />,
            handle: {
              title: 'Çoklu Yetki',
              condition: () =>
                hasPermission(Operations.AddBulkPermissionsToRoles),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/roles',
            element: <Roles />,
            handle: {
              title: 'Roller',
              condition: () => hasPermission(Operations.GetOrganizationRoles),
              redirectTo: '/not-found',
            },
            children: [
              {
                path: 'create',
                element: <Permissions />,
                handle: {
                  title: 'Rol Oluştur',
                  condition: () =>
                    hasPermission(Operations.CreateOrganizationRole),
                  redirectTo: '/not-found',
                },
              },
              {
                path: ':roleID/permissions',
                element: <Permissions />,
                handle: {
                  title: 'Yetkiler',
                  condition: () =>
                    hasPermission(Operations.GetOrganizationRolePermissions),
                  redirectTo: '/not-found',
                },
              },
            ],
          },
        ],
      },
      {
        path: '/notifications',
        text: 'Bildirimler',
        isOnSidebar: () =>
          !isProduction || hasPermission(Operations.GetAllPermissions),
        icon: 'icon-[mdi--announcement-outline] size-6',
        handle: {
          title: 'Bildirim Ayarları',
          condition: () =>
            !isProduction || hasPermission(Operations.GetAllPermissions),
          redirectTo: '/not-found',
        },
        children: [
          {
            element: <NotificationSettings />,
            index: true,
          },
          {
            path: 'templates/create',
            element: <TemplateForm />,
            handle: {
              title: 'Bildirim Şablonu Oluştur',
              condition: () => hasPermission(Operations.CreateTemplate),
              redirectTo: '/not-found',
            },
          },
          {
            path: 'templates/:id/update',
            element: <TemplateForm />,
            handle: {
              title: 'Bildirim Şablonu Güncelle',
              condition: () => hasPermission(Operations.UpdateTemplate),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'my-organization',
        text: 'Organizasyonum',
        isOnSidebar: () => hasPermission(Operations.GetMyOrganization),
        icon: 'icon-[mdi--home-city-outline] size-6',
        handle: {
          title: 'Organizasyonum',
          condition: () => hasPermission(Operations.GetMyOrganization),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <MyOrganization />,
          },
        ],
      },
      {
        path: 'e12-employee',
        text: 'E12 Personeli',
        isOnSidebar: () => hasPermission(Operations.CreateE12Employee),
        icon: 'icon-[material-symbols--verified-user-outline-rounded] size-6',
        handle: {
          title: 'E12 Personeli Oluştur',
          condition: () => hasPermission(Operations.CreateE12Employee),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <E12EmployeeForm />,
          },
        ],
      },
      {
        path: 'audits',
        text: 'Denetim Kayıtları',
        isOnSidebar: () => hasPermission(Operations.GetAuiditLogList),
        icon: 'icon-[material-symbols--person-search-outline-rounded] size-6',
        handle: {
          title: 'Denetim Kayıtları',
          condition: () => hasPermission(Operations.GetAuiditLogList),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <Audits />,
          },
        ],
      },
      {
        path: '/landing-page',
        text: 'Landing Page',
        isOnSidebar: () =>
          hasPermission(Operations.GetApplicationList) ||
          hasPermission(Operations.GetNewsletterList),
        icon: 'icon-[material-symbols--list-alt-outline] size-6',
        handle: {
          title: 'Landing Page',
          condition: () =>
            hasPermission(Operations.GetApplicationList) ||
            hasPermission(Operations.GetNewsletterList),
          handle: true,
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <LandingPage />,
          },
        ],
      },
      {
        path: 'curriculum',
        text: 'Müfredatlar',
        isOnSidebar: () => hasPermission(Operations.GetCurriculumListFE),
        icon: 'icon-[material-symbols--home-storage-outline-rounded] size-6',
        handle: {
          title: 'Müfredatlar',
          condition: () => hasPermission(Operations.GetCurriculumListFE),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <Curriculum />,
          },
        ],
      },
      {
        path: 'lessons',
        text: 'Dersler',
        isOnSidebar: () => hasPermission(Operations.GetLessonListFE),
        icon: 'icon-[material-symbols--menu-book-outline-rounded] size-6',
        handle: {
          title: 'Dersler',
          condition: () => hasPermission(Operations.GetLessonListFE),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <Lessons />,
          },
          {
            path: 'create',
            element: <LessonForm />,
            handle: {
              title: 'Ders Oluştur',
              condition: () => hasPermission(Operations.CreateLesson),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateLesson),
          },
          {
            path: ':id/update',
            element: <LessonForm />,
            handle: {
              title: 'Ders Güncelle',
              condition: () => hasPermission(Operations.UpdateLesson),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'learning-domain',
        text: 'Öğrenme Alanları',
        isOnSidebar: () => hasPermission(Operations.GetLearningDomainList),
        icon: 'icon-[material-symbols--bookmark-outline-rounded] size-6',
        handle: {
          title: 'Öğrenme Alanları',
          condition: () => hasPermission(Operations.GetLearningDomainList),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <LearningDomain />,
          },
          {
            path: 'create',
            element: <LearningDomainForm />,
            handle: {
              title: 'Öğrenme Alanı Oluştur',
              condition: () => hasPermission(Operations.CreateLearningDomain),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateLearningDomain),
          },
          {
            path: ':id/update',
            element: <LearningDomainForm />,
            handle: {
              title: 'Öğrenme Alanı Güncelle',
              condition: () => hasPermission(Operations.UpdateLearningDomain),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'sub-learning-domain',
        text: 'Alt Öğrenme Alanları',
        isOnSidebar: () => hasPermission(Operations.GetSubLearningDomainList),
        icon: 'icon-[material-symbols--bookmarks-outline-rounded] size-6',
        handle: {
          title: 'Alt Öğrenme Alanları',
          condition: () => hasPermission(Operations.GetSubLearningDomainList),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <SubLearningDomain />,
          },
          {
            path: 'create',
            element: <SubLearningDomainForm />,
            handle: {
              title: 'Alt Öğrenme Alanı Oluştur',
              condition: () =>
                hasPermission(Operations.CreateSubLearningDomain),
              redirectTo: '/not-found',
            },
            isQuickAction: () =>
              hasPermission(Operations.CreateSubLearningDomain),
          },
          {
            path: ':learningDomainID/:subLearningDomainID/update',
            element: <SubLearningDomainForm />,
            handle: {
              title: 'Alt Öğrenme Alanı Güncelle',
              condition: () =>
                hasPermission(Operations.UpdateSubLearningDomain),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'subject',
        text: 'Konular',
        isOnSidebar: () => hasPermission(Operations.GetSubjectListFE),
        icon: 'icon-[material-symbols--book-2-outline-rounded] size-6',
        handle: {
          title: 'Konular',
          condition: () => hasPermission(Operations.GetSubjectListFE),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <Subject />,
          },
          {
            path: 'create',
            element: <SubjectForm />,
            handle: {
              title: 'Konu Oluştur',
              condition: () => hasPermission(Operations.CreateSubject),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateSubject),
          },
          {
            path: ':subjectID/update',
            element: <SubjectForm />,
            handle: {
              title: 'Konuyu Güncelle',
              condition: () => hasPermission(Operations.UpdateSubject),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'learning-outcomes',
        text: 'Kazanımlar',
        isOnSidebar: () => hasPermission(Operations.GetLearningOutcomeListFE),
        icon: 'icon-[material-symbols--trophy-outline-rounded] size-6',
        handle: {
          title: 'Kazanımlar',
          condition: () => hasPermission(Operations.GetLearningOutcomeListFE),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <LearningOutcomes />,
          },
          {
            path: 'create',
            element: <LearningOutcomeForm />,
            handle: {
              title: 'Kazanım Oluştur',
              condition: () => hasPermission(Operations.CreateLearningOutcome),
              redirectTo: '/not-found',
            },
            isQuickAction: () =>
              hasPermission(Operations.CreateLearningOutcome),
          },
          {
            path: ':learningOutcomeID/update',
            element: <LearningOutcomeForm />,
            handle: {
              title: 'Kazanımı Güncelle',
              condition: () => hasPermission(Operations.UpdateLearningOutcome),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'sub-learning-outcomes',
        text: 'Alt Kazanımlar',
        isOnSidebar: () => hasPermission(Operations.GetSubLearningOutcomeList),
        icon: 'icon-[material-symbols--social-leaderboard-outline-rounded] size-6',
        handle: {
          title: ' Alt Kazanımlar',
          condition: () => hasPermission(Operations.GetSubLearningOutcomeList),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <SubLearningOutcomes />,
          },
          {
            path: 'create',
            element: <SubLearningOutcomeForm />,
            handle: {
              title: 'Alt Kazanım Oluştur',
              condition: () =>
                hasPermission(Operations.CreateSubLearningOutcome),
              redirectTo: '/not-found',
            },
            isQuickAction: () =>
              hasPermission(Operations.CreateSubLearningOutcome),
          },
          {
            path: ':learningOutcomeID/:subLearningOutcomeID/update',
            element: <SubLearningOutcomeForm />,
            handle: {
              title: 'Alt Kazanımı Güncelle',
              condition: () =>
                hasPermission(Operations.UpdateSubLearningOutcome),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'specialties',
        text: 'Branşlar',
        icon: 'icon-[material-symbols--event-list-outline-rounded] size-6',
        isOnSidebar: () => hasPermission(Operations.GetSpecialtiesFE),
        handle: {
          title: 'Branşlar',
          condition: () => hasPermission(Operations.GetSpecialtiesFE),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <Specialties />,
          },
        ],
      },
    ],
  },
  {
    element: <Layout />,
    text: 'Yapay Zeka',
    children: [
      {
        path: 'ai-configs',
        text: 'AI Konfigürasyonları',
        isOnSidebar: () => hasPermission(Operations.GetAIConfigList),
        icon: 'icon-[material-symbols--smart-toy-outline-rounded] size-6',
        handle: {
          title: 'AI Konfigürasyonları',
          condition: () => hasPermission(Operations.GetAIConfigList),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <AIConfigs />,
          },
          {
            path: 'create',
            element: <AIConfigForm />,
            handle: {
              title: 'AI Konfigürasyonu Oluştur',
              condition: () => hasPermission(Operations.CreateAIConfig),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/update',
            element: <AIConfigForm />,
            handle: {
              title: 'AI Konfigürasyonu Güncelle',
              condition: () => hasPermission(Operations.UpdateAIConfig),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'chatbot',
        text: 'E12 Asistan',
        isOnSidebar: () => hasPermission(Operations.UseChatbot),
        icon: 'icon-[material-symbols--neurology-outline-rounded] size-6',
        handle: {
          title: 'E12 Asistan',
          condition: () => hasPermission(Operations.UseChatbot),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <Chatbot />,
          },
        ],
      },
      {
        path: 'solver',
        text: 'E12 Solver',
        isOnSidebar: () =>
          hasPermission(Operations.GetSolverQuestionList) &&
          (!isProduction || hasPermission(Operations.GetAllPermissions)),
        icon: 'icon-[mdi--head-question-outline] size-6',
        handle: {
          title: 'E12 Solver',
          condition: () =>
            hasPermission(Operations.GetSolverQuestionList) &&
            (!isProduction || hasPermission(Operations.GetAllPermissions)),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <SolverQuestions />,
          },
          {
            path: ':id/answers',
            element: <SolverQuestionForm />,
            handle: {
              title: 'Cevaplar',
              condition: () =>
                hasPermission(Operations.UpdateSolverAnswer) ||
                hasPermission(Operations.AnswerSolverQuestion),
              redirectTo: '/not-found',
            },
          },
        ],
      },
    ],
  },
  {
    element: <Layout />,
    text: 'Kampüs İşlemleri',
    children: [
      {
        path: '/campuses',
        text: 'Kampüsler',
        isOnSidebar: () => hasPermission(Operations.GetCampusesOverviewFE),
        icon: 'icon-[material-symbols--villa-outline-rounded] size-6',
        handle: {
          title: 'Kampüsler',
          condition: () => hasPermission(Operations.GetCampusesOverviewFE),
          redirectTo: '/not-found',
        },
        children: [
          {
            element: <Campuses />,
            index: true,
          },
          {
            path: ':campusID/announcement/create',
            element: <AnnouncementForm />,
            handle: {
              title: 'Duyuru Oluştur',
              condition: () =>
                hasPermission(Operations.CreateCampusAnnouncement),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: '/departments',
        text: 'Birimler',
        isOnSidebar: () =>
          hasPermission(Operations.GetCampusDepartmentsOverview),
        icon: 'icon-[material-symbols--account-tree-outline-rounded] size-6',
        handle: {
          title: 'Birimler',
          condition: () =>
            hasPermission(Operations.GetCampusDepartmentsOverview),
          redirectTo: '/not-found',
        },
        children: [
          {
            element: <Departments />,
            index: true,
          },
          {
            path: ':campusID/:departmentID/announcement/create',
            element: <AnnouncementForm />,
            handle: {
              title: 'Duyuru Oluştur',
              condition: () =>
                hasPermission(Operations.CreateDepartmentAnnouncement),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: '/grades',
        text: 'Sınıflar',
        isOnSidebar: () => hasPermission(Operations.GetGradeListOverview),
        icon: 'icon-[material-symbols--door-open-outline-rounded] size-6',
        handle: {
          title: 'Sınıflar',
          condition: () => hasPermission(Operations.GetGradeListOverview),
          redirectTo: '/not-found',
        },
        children: [
          {
            element: <Grades />,
            index: true,
          },
          {
            path: ':campusID/:gradeID/announcement/create',
            element: <AnnouncementForm />,
            handle: {
              title: 'Duyuru Oluştur',
              condition: () =>
                !isProduction || hasPermission(Operations.GetAllPermissions),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: '/classes',
        text: 'Şubeler',
        isOnSidebar: () => hasPermission(Operations.GetClassListFE),
        icon: 'icon-[material-symbols--school-outline-rounded] size-6',
        handle: {
          title: 'Şubeler',
          condition: () => hasPermission(Operations.GetClassListFE),
          redirectTo: '/not-found',
        },
        children: [
          {
            element: <Classes />,
            index: true,
          },
          {
            path: ':campusID/:classID/announcement/create',
            element: <AnnouncementForm />,
            handle: {
              title: 'Duyuru Oluştur',
              condition: () =>
                hasPermission(Operations.CreateClassAnnouncement),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/tracking-note/',
            element: <TrackingNote entityType="classes" />,
            handle: {
              title: 'Takip Notu',
              condition: () =>
                hasPermission(Operations.GetLessonTrackingNoteList),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/tracking-note/create',
            element: <TrackingNoteClassesForm />,
            handle: {
              title: 'Takip Notu Oluştur',
              condition: () =>
                hasPermission(Operations.CreateLessonTrackingNote),
              redirectTo: '/not-found',
            },
          },
          {
            path: 'tracking-note/:id/update/:trackingNoteID',
            element: <TrackingNoteClassesForm />,
            handle: {
              title: 'Takip Notu Güncelle',
              condition: () =>
                hasPermission(Operations.UpdateLessonTrackingNote),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'meetings',
        text: 'Toplantılar',
        isOnSidebar: () => hasPermission(Operations.GetMeetingList),
        icon: 'icon-[mdi--virtual-meeting] size-6',
        handle: {
          title: 'Toplantılar',
          condition: () => hasPermission(Operations.GetMeetingList),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <Meetings />,
          },
        ],
      },
      {
        path: 'announcements',
        text: 'Duyuru Havuzu',
        isOnSidebar: () => hasPermission(Operations.GetAnnouncementList),
        icon: 'icon-[material-symbols--campaign-outline-rounded] size-6',
        handle: {
          title: 'Duyuru Havuzu',
          condition: () => hasPermission(Operations.GetAnnouncementList),
          redirectTo: '/not-found',
        },
        children: [
          {
            element: <Announcements />,
            index: true,
          },
          {
            path: ':id/update',
            element: <AnnouncementForm />,
            handle: {
              title: 'Duyuru Düzenle',
              condition: () => hasPermission(Operations.UpdateAnnouncement),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'my-announcements',
        text: 'Duyurularım',
        isOnSidebar: () => hasPermission(Operations.GetMyAnnouncementList),
        icon: 'icon-[material-symbols--notifications-outline-rounded] size-6',
        handle: {
          title: 'Duyurularım',
          condition: () => hasPermission(Operations.GetMyAnnouncementList),
          redirectTo: '/not-found',
        },
        children: [
          {
            element: <MyAnnouncements />,
            index: true,
          },
          {
            path: ':id/update',
            element: <MyAnnouncementForm />,
            handle: {
              title: 'Duyuru Düzenle',
              condition: () => hasPermission(Operations.UpdateMyAnnouncement),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'demands',
        text: 'Talepler',
        isOnSidebar: () => hasPermission(Operations.GetDemandList),
        icon: 'icon-[material-symbols--mark-as-unread-outline-rounded] size-6',
        handle: {
          title: 'Talepler',
          condition: () => hasPermission(Operations.GetDemandList),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <Demands />,
          },
        ],
      },
      {
        path: '/places',
        text: 'Mekanlar',
        isOnSidebar: () => hasPermission(Operations.GetPlaceList),
        icon: 'icon-[material-symbols--location-on-outline-rounded] size-6',
        handle: {
          title: 'Mekanlar',
          condition: () => hasPermission(Operations.GetPlaceList),
          redirectTo: '/not-found',
        },
        children: [
          {
            element: <Places />,
            index: true,
          },
          {
            path: 'create',
            element: <PlaceForm />,
            handle: {
              title: 'Mekan Oluştur',
              condition: () => hasPermission(Operations.CreatePlace),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreatePlace),
          },
          {
            path: ':id/update',
            element: <PlaceForm />,
            handle: {
              title: 'Mekanı Düzenle',
              condition: () => hasPermission(Operations.UpdatePlace),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: '/attendance',
        text: 'Yoklama',
        isOnSidebar: () => hasPermission(Operations.GetAttendanceList),
        icon: 'icon-[material-symbols--person-raised-hand-outline-rounded] size-6',
        handle: {
          title: 'Yoklama',
          condition: () => hasPermission(Operations.GetAttendanceList),
          redirectTo: '/not-found',
        },
        children: [
          {
            element: <Attendance />,
            index: true,
          },
          {
            path: ':id/take',
            element: <TakeAttendance />,
            handle: {
              title: 'Yoklama Al',
              condition: () => hasPermission(Operations.GetAttendance),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/detail/:date',
            element: <AttendanceDetail />,
            handle: {
              title: 'Yoklama Detay',
              condition: () => hasPermission(Operations.GetAttendanceDetail),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: '/homework',
        text: 'Ödevler',
        isOnSidebar: () => hasPermission(Operations.GetHomeworkList),
        icon: 'icon-[material-symbols--menu-book-outline-rounded] size-6',
        handle: {
          title: 'Ödevler',
          condition: () => hasPermission(Operations.GetHomeworkList),
          redirectTo: '/not-found',
        },
        children: [
          {
            element: <Homework />,
            index: true,
          },
          {
            path: 'create',
            element: <HomeworkFormMenu />,
            handle: {
              title: 'Ödev Oluştur',
              condition: () => hasPermission(Operations.CreateHomework),
              redirectTo: '/not-found',
            },
            children: [
              {
                index: true,
                path: 'manuel',
                handle: {
                  activeIndex: 0,
                  title: 'Manuel Ödev Oluştur',
                  condition: () => hasPermission(Operations.CreateHomework),
                  redirectTo: '/not-found',
                },
                element: <HomeworkForm />,
                isQuickAction: () => hasPermission(Operations.CreateHomework),
              },
              {
                path: 'e12_form',
                handle: {
                  activeIndex: 1,
                  title: "E12'den Ödev Oluştur",
                  condition: () => hasPermission(Operations.CreateHomework),
                  redirectTo: '/not-found',
                },
                element: <HomeworkE12Form />,
                isQuickAction: () => hasPermission(Operations.CreateHomework),
              },
            ],
          },
          {
            path: ':id/update',
            element: <HomeworkFormMenu />,
            handle: {
              title: 'Ödev Güncelle',
              condition: () => hasPermission(Operations.UpdateHomework),
              redirectTo: '/not-found',
            },
            children: [
              {
                index: true,
                path: 'manuel',
                handle: {
                  activeIndex: 0,
                  title: 'Manuel Ödev Güncelle',
                  condition: () => hasPermission(Operations.UpdateHomework),
                  redirectTo: '/not-found',
                },
                element: <HomeworkForm />,
              },
              {
                path: 'e12_form',
                handle: {
                  activeIndex: 1,
                  title: "E12'den Ödev Güncelle",
                  condition: () => hasPermission(Operations.UpdateHomework),
                  redirectTo: '/not-found',
                },
                element: <HomeworkE12Form />,
              },
            ],
          },
          {
            path: ':id/assignments',
            element: <HomeworkAssignments />,
            handle: {
              title: 'Ödev Değerlendirme',
              condition: () => hasPermission(Operations.GetHomework),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id',
            element: <HomeworkReport />,
            handle: {
              condition: () => hasPermission(Operations.GetHomework),
              redirectTo: '/not-found',
            },
            children: [
              {
                index: true,
                path: 'list',
                handle: {
                  activeIndex: 0,
                  title: 'Rapor Tablo',
                },
                element: <HomeworkReportList />,
              },
              {
                path: 'chart',
                handle: { activeIndex: 1, title: 'Rapor Grafik' },
                element: <HomeworkReportChart />,
              },
              {
                path: 'comparative_report',
                handle: { activeIndex: 2, title: 'Karşılaştırmalı Rapor' },
                element: <HomeworkComparativeReport />,
              },
            ],
          },
        ],
      },
      {
        path: '/study',
        text: 'Etüt (M)',
        // isOnSidebar: () => hasPermission(Operations.GetStudyList),
        isOnSidebar: () =>
          !isProduction || hasPermission(Operations.GetAllPermissions),
        icon: 'icon-[material-symbols--collections-bookmark-outline] size-6',
        handle: {
          title: 'Etüt',
          condition: () =>
            hasPermission(Operations.GetStudyList) &&
            (!isProduction || hasPermission(Operations.GetAllPermissions)),
          redirectTo: '/not-found',
        },
        children: [
          {
            element: <Study />,
            index: true,
          },
          {
            path: 'create',
            element: <StudyForm />,
            handle: {
              title: 'Etüt Oluştur (M)',
              condition: () =>
                hasPermission(Operations.CreateStudy) &&
                (!isProduction || hasPermission(Operations.GetAllPermissions)),
              redirectTo: '/not-found',
            },
            // isQuickAction: () => hasPermission(Operations.CreateStudy),
          },
          {
            path: ':id/update',
            element: <StudyForm />,
            handle: {
              title: 'Etüt Güncelle (M)',
              condition: () =>
                hasPermission(Operations.UpdateStudy) &&
                (!isProduction || hasPermission(Operations.GetAllPermissions)),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/attendance',
            element: <StudyAttendance />,
            handle: {
              title: 'Yoklama al (M)',
              condition: () =>
                hasPermission(Operations.GetStudyAttendance) &&
                (!isProduction || hasPermission(Operations.GetAllPermissions)),
              redirectTo: '/not-found',
            },
          },
        ],
      },
    ],
  },
  {
    element: <Layout />,
    text: 'Ders ve İçerik İşlemleri',
    children: [
      {
        path: 'lesson-contents',
        text: 'İçerikler',
        isOnSidebar: () => hasPermission(Operations.GetLessonContents),
        icon: 'icon-[material-symbols--video-library-outline-rounded] size-6',
        handle: {
          title: 'İçerikler',
          condition: () => hasPermission(Operations.GetLessonContents),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <LessonContents />,
          },
          {
            path: 'create',
            element: <LessonContentForm />,
            handle: {
              title: 'İçerik Oluştur',
              condition: () => hasPermission(Operations.CreateLessonContent),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateLessonContent),
          },
          {
            path: ':id/update',
            element: <LessonContentForm />,
            handle: {
              title: 'İçerik Düzenle',
              condition: () => hasPermission(Operations.UpdateLessonContent),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/resources',
            element: <LessonContentResources />,
            handle: {
              condition: () => hasPermission(Operations.UpdateLessonContent),
              redirectTo: '/not-found',
            },
            children: [
              {
                index: true,
                path: 'interactive',
                element: <InteractiveContents />,
                handle: {
                  title: 'İçerik Düzenleme İşlemleri',
                  activeIndex: 0,
                  condition: () =>
                    hasPermission(Operations.UpdateLessonContent),
                  redirectTo: '/not-found',
                },
              },
              {
                path: 'video',
                element: <Video />,
                handle: {
                  title: 'İçerik Düzenleme İşlemleri',
                  activeIndex: 1,
                  condition: () =>
                    hasPermission(Operations.UpdateLessonContent),
                  redirectTo: '/not-found',
                },
              },
              {
                path: 'pdf',
                element: <PdfResources />,
                handle: {
                  title: 'İçerik Düzenleme İşlemleri',
                  activeIndex: 2,
                  condition: () =>
                    hasPermission(Operations.UpdateLessonContent),
                  redirectTo: '/not-found',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'video-pool',
        text: 'Video Havuzu',
        isOnSidebar: () => hasPermission(Operations.GetVideos),
        icon: 'icon-[material-symbols--videocam-outline-rounded] size-6',
        handle: {
          title: 'Video Havuzu',
          condition: () => hasPermission(Operations.GetVideos),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <VideoPool />,
          },
          {
            path: 'create',
            element: <VideoPoolForm />,
            handle: {
              title: 'Video Havuzu Oluştur',
              condition: () => hasPermission(Operations.CreateVideo),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateVideo),
          },
          {
            path: ':id/update',
            element: <VideoPoolForm />,
            handle: {
              title: 'Video Havuzu Düzenle',
              condition: () => hasPermission(Operations.UpdateVideo),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'question-pool',
        text: 'Soru Havuzu',
        isOnSidebar: () => hasPermission(Operations.GetQuestionList),
        icon: 'icon-[mdi--frequently-asked-questions] size-6',
        handle: {
          title: 'Soru Havuzu',
          condition: () => hasPermission(Operations.GetQuestionList),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <QuestionPool />,
          },
          {
            path: 'create',
            element: <QuestionPoolForm />,
            handle: {
              title: 'Soru Havuzu Oluştur',
              condition: () => hasPermission(Operations.CreateQuestion),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateQuestion),
          },
          {
            path: ':id/update',
            element: <QuestionPoolForm />,
            handle: {
              title: 'Soru Havuzu Düzenle',
              condition: () => hasPermission(Operations.UpdateQuestion),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'quiz-pool',
        text: 'Quiz Havuzu',
        isOnSidebar: () => hasPermission(Operations.GetQuizPoolList),
        icon: 'icon-[material-symbols--quiz-outline-rounded] size-6',
        handle: {
          title: 'Quiz Havuzu',
          condition: () => hasPermission(Operations.GetQuizPoolList),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <QuizPool />,
          },
          {
            path: 'create',
            element: <QuizPoolForm />,
            handle: {
              title: 'Quiz Havuzu Oluştur',
              condition: () => hasPermission(Operations.CreateQuizPool),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateQuizPool),
          },
          {
            path: ':id/update',
            element: <QuizPoolForm />,
            handle: {
              title: 'Quiz Havuzu Düzenle',
              condition: () => hasPermission(Operations.UpdateQuizPool),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'annual-plans',
        text: 'Yıllık Planlar',
        isOnSidebar: () => hasPermission(Operations.GetAnnualPlanListFE),
        icon: 'icon-[material-symbols--calendar-clock-outline-rounded] size-6',
        handle: {
          title: 'Yıllık Planlar',
          condition: () => hasPermission(Operations.GetAnnualPlanListFE),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <AnnualPlans />,
          },
          {
            path: 'create',
            element: <AnnualPlanForm />,
            handle: {
              title: 'Yıllık Plan Oluştur',
              condition: () => hasPermission(Operations.CreateAnnualPlan),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateAnnualPlan),
          },
          {
            path: ':id/update',
            element: <AnnualPlanForm />,
            handle: {
              title: 'Yıllık Plan Güncelle',
              condition: () => hasPermission(Operations.UpdateAnnualPlan),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/duplicate',
            element: <AnnualPlanForm />,
            handle: {
              title: 'Yıllık Plan Kopyala',
              condition: () => hasPermission(Operations.UpdateAnnualPlan),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/assign',
            element: <AnnualPlanAssignForm />,
            handle: {
              title: 'Yıllık Plan Ata',
              condition: () => hasPermission(Operations.UpdateAnnualPlan),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/weeks',
            element: <AnnualPlanWeekly />,
            handle: {
              title: 'Yıllık Plan Düzenleme İşlemleri',
              condition: () => hasPermission(Operations.UpdateAnnualPlan),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/overview',
            element: <AnnualPlanOverview />,
            handle: {
              title: 'Yıllık Plan Detay',
              condition: () => hasPermission(Operations.GetAnnualPlan),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'live-lessons',
        text: 'Canlı Ders',
        isOnSidebar: () => hasPermission(Operations.GetLiveLessons),
        icon: 'icon-[material-symbols--live-tv-outline-rounded] size-6',
        handle: {
          title: 'Canlı Ders',
          condition: () => hasPermission(Operations.GetLiveLessons),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <LiveLesson isOldLiveLesson={false} />,
          },
          {
            path: 'create',
            element: <LiveLessonForm />,
            handle: {
              title: 'Canlı Ders Oluştur',
              condition: () => hasPermission(Operations.CreateLiveLesson),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateLiveLesson),
          },
          {
            path: ':id/update',
            element: <LiveLessonForm />,
            handle: {
              title: 'Canlı Ders Güncelle',
              condition: () => hasPermission(Operations.UpdateLiveLesson),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'old-live-lessons',
        text: 'Geçmiş Canlı Ders',
        isOnSidebar: () => hasPermission(Operations.GetLiveLessons),
        icon: 'icon-[material-symbols--tv-outline-rounded] size-6',
        handle: {
          title: 'Geçmiş Canlı Ders',
          condition: () => hasPermission(Operations.GetLiveLessons),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <LiveLesson isOldLiveLesson />,
          },
        ],
      },
    ],
  },
  {
    element: <Layout />,
    text: 'Kullanıcı işlemleri',
    children: [
      {
        path: 'employee',
        text: 'Personeller',
        isOnSidebar: () => hasPermission(Operations.GetEmployeeList),
        icon: 'icon-[material-symbols--person-outline-rounded] size-6',
        handle: {
          title: 'Personeller',
          condition: () => hasPermission(Operations.GetEmployeeList),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <Employee />,
          },
          {
            path: 'create',
            element: <EmployeeForm />,
            handle: {
              title: 'Personel Oluştur',
              condition: () => hasPermission(Operations.CreateUser),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateUser),
          },
          {
            path: ':id/update',
            element: <EmployeeForm />,
            handle: {
              title: 'Personel Güncelle',
              condition: () => hasPermission(Operations.CreateUser),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/schedule',
            element: <EmployeeSchedule />,
            handle: {
              title: 'Personel Ders Programı',
              condition: () => hasPermission(Operations.GetEmployeeDetail),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/activity',
            handle: {
              title: 'Personel Aktivitesi',
              condition: () =>
                hasPermission(Operations.GetHomeworkReport) ||
                hasPermission(Operations.GetStudentTrackingNoteReport),
              redirectTo: '/not-found',
            },
            children: [
              {
                index: true,
                element: <EmployeeReports />,
              },
            ],
          },
        ],
      },
      {
        path: 'students',
        text: 'Öğrenciler',
        isOnSidebar: () => hasPermission(Operations.GetStudentListFE),
        icon: 'icon-[material-symbols--group-outline-rounded] size-6',
        handle: {
          title: 'Öğrenciler',
          condition: () => hasPermission(Operations.GetStudentListFE),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <Students />,
          },
          {
            path: 'create',
            element: <StudentForm />,
            handle: {
              title: 'Öğrenci Oluştur',
              condition: () => hasPermission(Operations.CreateUser),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateUser),
          },
          {
            path: ':id/update',
            element: <StudentForm />,
            handle: {
              title: 'Öğrenci Güncelle',
              condition: () => hasPermission(Operations.CreateUser),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/tracking-note',
            element: <TrackingNote entityType="student" />,
            handle: {
              title: 'Takip Notu',
              condition: () =>
                hasPermission(Operations.GetLessonTrackingNoteList),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/tracking-note/create',
            element: <TrackingNoteForm />,
            handle: {
              title: 'Takip Notu Oluştur',
              condition: () =>
                hasPermission(Operations.CreateLessonTrackingNote),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/tracking-note/:trackingNoteID/update',
            element: <TrackingNoteForm />,
            handle: {
              title: 'Takip Notu Güncelle',
              condition: () =>
                hasPermission(Operations.UpdateLessonTrackingNote),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/student-report-and-activity',
            text: 'Öğrenci Rapor ve Aktivite (M)',
            handle: {
              title: 'Öğrenci Rapor ve Aktivite (M)',
              condition: () =>
                hasPermission(Operations.GetStudentReportAndActivity) ||
                !isProduction ||
                hasPermission(Operations.GetAllPermissions),
              redirectTo: '/not-found',
            },
            children: [
              {
                index: true,
                element: <StudentReportAndActivity />,
              },
              {
                index: true,
                path: 'detail',
                handle: {
                  activeIndex: 0,
                  title: 'Aktivite Detay (M)',
                  condition: () =>
                    hasPermission(Operations.GetStudentReportAndActivity),
                  redirectTo: '/not-found',
                },
                element: <StudentActivityDashboard />,
              },
            ],
          },
        ],
      },
      {
        path: 'infirmary',
        text: 'Revir',
        isOnSidebar: () => hasPermission(Operations.GetStudentList),
        icon: 'icon-[material-symbols--health-and-safety-outline-rounded] size-6',
        handle: {
          title: 'Revir',
          condition: () => hasPermission(Operations.GetStudentList),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <Infirmary />,
          },
          {
            path: ':id/create',
            element: <InfirmaryForm />,
            handle: {
              title: 'Revir Kaydı Oluştur',
              condition: () => hasPermission(Operations.CreateInfirmary),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateInfirmary),
          },
          {
            path: ':id/:infirmaryID/update',
            element: <InfirmaryForm />,
            handle: {
              title: 'Revir Kaydı Güncelle',
              condition: () => hasPermission(Operations.UpdateInfirmary),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id',
            element: <InfirmaryList />,
            handle: {
              title: 'Revir Detay',
              condition: () => hasPermission(Operations.GetInfirmaryList),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'groups',
        text: 'Gruplar',
        isOnSidebar: () => hasPermission(Operations.GetGroupList),
        icon: 'icon-[material-symbols--groups-outline-rounded] size-6',
        handle: {
          title: 'Gruplar',
          condition: () => hasPermission(Operations.GetGroupList),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <Groups />,
          },
          {
            path: 'create',
            element: <GroupForm />,
            handle: {
              title: 'Grup Oluştur',
              condition: () => hasPermission(Operations.CreateGroup),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateGroup),
          },
          {
            path: ':id/update',
            element: <GroupForm />,
            handle: {
              title: 'Grup Güncelle',
              condition: () => hasPermission(Operations.UpdateGroup),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':groupID/meeting/create',
            element: <GroupMeetingForm />,
            handle: {
              title: 'Grup Toplantı Oluştur',
              condition: () => hasPermission(Operations.CreateMeeting),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':groupID/meeting/:id/update',
            element: <GroupMeetingForm />,
            handle: {
              title: 'Grup Toplantı Güncelle',
              condition: () => hasPermission(Operations.UpdateMeeting),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':groupID/demand/create',
            element: <GroupDemandForm />,
            handle: {
              title: 'Grup Talep Oluştur',
              condition: () => hasPermission(Operations.CreateDemand),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateDemand),
          },
          {
            path: ':groupID/demand/:id/update',
            element: <GroupDemandForm />,
            handle: {
              title: 'Grup Talep Düzenle',
              condition: () => hasPermission(Operations.UpdateDemand),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':groupID/announcement/create',
            element: <AnnouncementForm />,
            handle: {
              title: 'Duyuru Oluştur',
              condition: () =>
                hasPermission(Operations.CreateGroupAnnouncement) &&
                (!isProduction || hasPermission(Operations.GetAllPermissions)),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'societies',
        text: 'Zümreler',
        isOnSidebar: () => hasPermission(Operations.GetSocietyList),
        icon: 'icon-[material-symbols--groups-2-outline-rounded] size-6',
        handle: {
          title: 'Zümreler',
          condition: () => hasPermission(Operations.GetSocietyList),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <Societies />,
          },
          {
            path: 'create',
            element: <SocietyForm />,
            handle: {
              title: 'Zümre Oluştur',
              condition: () => hasPermission(Operations.CreateSociety),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateSociety),
          },
          {
            path: ':id/update',
            element: <SocietyForm />,
            handle: {
              title: 'Zümre Güncelle',
              condition: () => hasPermission(Operations.UpdateSociety),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':societyID/meeting/create',
            element: <SocietyMeetingForm />,
            handle: {
              title: 'Zümre Toplantı Oluştur',
              condition: () => hasPermission(Operations.CreateMeeting),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateMeeting),
          },
          {
            path: ':societyID/meeting/:id/update',
            element: <SocietyMeetingForm />,
            handle: {
              title: 'Zümre Toplantı Güncelle',
              condition: () => hasPermission(Operations.UpdateMeeting),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':societyID/announcement/create',
            element: <AnnouncementForm />,
            handle: {
              title: 'Duyuru Oluştur',
              condition: () =>
                hasPermission(Operations.CreateSocietyAnnouncement),
              redirectTo: '/not-found',
            },
          },
        ],
      },
    ],
  },
  {
    element: <Layout />,
    text: 'Çizelgeler',
    children: [
      {
        path: 'unit-timelines',
        text: 'Birim Zaman Çizelgeleri',
        isOnSidebar: () => hasPermission(Operations.GetUnitTimelineList),
        icon: 'icon-[material-symbols--timer-outline-rounded] size-6',
        handle: {
          title: 'Birim Zaman Çizelgeleri',
          condition: () => hasPermission(Operations.GetUnitTimelineList),
          handle: true,
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <UnitTimelines />,
          },
          {
            path: ':campusID/:departmentID',
            element: <CreateUnitTimeline />,
            handle: {
              title: 'Birim Zaman Çizelgesi',
              condition: () => hasPermission(Operations.GetUnitTimelineList),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'class-schedules',
        text: 'Şube Ders Programları',
        isOnSidebar: () => hasPermission(Operations.GetClassScheduleList),
        icon: 'icon-[material-symbols--event-note-outline-rounded] size-6',
        handle: {
          title: 'Şube Ders Programları',
          condition: () => hasPermission(Operations.GetClassScheduleList),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <ClassSchedules />,
          },
          {
            path: ':classID',
            element: <CreateClassSchedule />,
            handle: {
              title: 'Şube Ders Programı',
              condition: () => hasPermission(Operations.UpdateClassSchedule),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':classID/preview',
            element: <CreateClassSchedule />,
            handle: {
              title: 'Şube Ders Programı',
              condition: () => hasPermission(Operations.GetClassSchedule),
              redirectTo: '/not-found',
            },
          },
        ],
      },
    ],
  },
  {
    element: <Layout />,
    text: 'Muhasebe İşlemleri',
    children: [
      {
        path: '/preaccounting/student-payments',
        text: 'Öğrenci Ödeme',
        isOnSidebar: () => hasPermission(Operations.GetAccPaymentPlanOverview),
        icon: 'icon-[material-symbols--payments-outline-rounded] size-6',
        handle: {
          title: 'Öğrenci Ödeme',
          condition: () => hasPermission(Operations.GetAccPaymentPlanOverview),
          handle: true,
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <StudentPayments />,
          },
          {
            path: ':studentID/create',
            element: <StudentPaymentsForm />,
            handle: {
              title: 'Öğrenci Ödeme Oluştur',
              condition: () => hasPermission(Operations.CreateAccPaymentPlan),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':studentID/update',
            element: <StudentPaymentsForm />,
            handle: {
              title: 'Öğrenci Ödeme Düzenle',
              condition: () => hasPermission(Operations.UpdateAccPaymentPlan),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':studentID/collection',
            element: <StudentPaymentCollection />,
            handle: {
              title: 'Öğrenci Tahsilat Bilgileri',
              condition: () =>
                hasPermission(Operations.GetAccCollectionOverview),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: '/preaccounting/vault-cashes',
        text: 'Kasa',
        isOnSidebar: () => hasPermission(Operations.GetAccVaultCashOverview),
        icon: 'icon-[material-symbols--account-balance-outline-rounded] size-6',
        handle: {
          title: 'Kasa',
          condition: () => hasPermission(Operations.GetAccVaultCashOverview),
          handle: true,
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <VaultCashes />,
          },
          {
            path: 'create',
            element: <VaultCashesForm />,
            handle: {
              title: 'Kasa Oluştur',
              condition: () => hasPermission(Operations.CreateAccVaultCash),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateAccVaultCash),
          },
          {
            path: ':id/update',
            element: <VaultCashesForm />,
            handle: {
              title: 'Kasa Düzenle',
              condition: () => hasPermission(Operations.UpdateAccVaultCash),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id',
            element: <VaultCashesDetail />,
            handle: {
              condition: () => hasPermission(Operations.GetAccVaultCashDetail),
              redirectTo: '/not-found',
            },
            children: [
              {
                index: true,
                path: 'income',
                handle: {
                  activeIndex: 0,
                  title: 'Kasa Detay',
                  condition: () =>
                    hasPermission(Operations.GetAccVaultCashDetail),
                  redirectTo: '/not-found',
                },
                element: <IncomeList />,
              },
              {
                path: 'expense',
                handle: {
                  activeIndex: 1,
                  title: 'Kasa Detay',
                  condition: () =>
                    hasPermission(Operations.GetAccVaultCashDetail),
                  redirectTo: '/not-found',
                },
                element: <ExpenseList />,
              },
              {
                path: 'net_profit',
                handle: {
                  activeIndex: 2,
                  title: 'Kasa Detay',
                  condition: () =>
                    hasPermission(Operations.GetAccVaultCashDetail),
                  redirectTo: '/not-found',
                },
                element: <NetProfit />,
              },
            ],
          },
          {
            path: ':id/expenses',
            element: <Expenses />,
            handle: {
              title: 'Kasa Gider Listesi',
              condition: () =>
                hasPermission(
                  Operations.GetAccExpenseOverview &&
                    Operations.GetAccSupplierList
                ),
              redirectTo: '/not-found',
            },
            children: [
              {
                path: 'create',
                element: <ExpenseForm />,
                handle: {
                  title: 'Kasa Gider Oluştur',
                  condition: () =>
                    hasPermission(
                      Operations.CreateAccEmployeeExpense &&
                        Operations.CreateAccOtherExpense
                    ),
                  redirectTo: '/not-found',
                },
              },
              {
                path: ':id/update',
                element: <ExpenseForm />,
                handle: {
                  title: 'Kasa Gider Düzenle',
                  condition: () => hasPermission(Operations.UpdateAccExpense),
                  redirectTo: '/not-found',
                },
              },
            ],
          },
        ],
      },
      {
        path: '/preaccounting/suppliers',
        text: 'Tedarikçi/Şirket',
        isOnSidebar: () => hasPermission(Operations.GetAccSupplierOverview),
        icon: 'icon-[material-symbols--store-outline-rounded] size-6',
        handle: {
          title: 'Tedarikçi/Şirket',
          condition: () => hasPermission(Operations.GetAccSupplierOverview),
          handle: true,
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <Suppliers />,
          },
          {
            path: 'create',
            element: <SupplierForm />,
            handle: {
              title: 'Tedarikçi/Şirket Oluştur',
              condition: () => hasPermission(Operations.CreateAccSupplier),
              redirectTo: '/not-found',
            },
            isQuickAction: () => hasPermission(Operations.CreateAccSupplier),
          },
          {
            path: ':id/update',
            element: <SupplierForm />,
            handle: {
              title: 'Tedarikçi/Şirket Düzenle',
              condition: () => hasPermission(Operations.UpdateAccSupplier),
              redirectTo: '/not-found',
            },
          },
        ],
      },
      {
        path: 'contracts',
        text: 'Sözleşmeler',
        isOnSidebar: () => hasPermission(Operations.GetContractList),
        icon: 'icon-[material-symbols--contract-outline-rounded] size-6',
        handle: {
          title: 'Sözleşmeler',
          condition: () => hasPermission(Operations.GetContractList),
          redirectTo: '/not-found',
        },
        children: [
          {
            index: true,
            element: <Contracts />,
          },
          {
            path: 'create',
            element: <ContractForm />,
            handle: {
              title: 'Sözleşme Oluştur',
              condition: () => hasPermission(Operations.CreateContract),
              redirectTo: '/not-found',
            },
          },
          {
            path: ':id/update',
            element: <ContractForm />,
            handle: {
              title: 'Sözleşme Güncelle',
              condition: () => hasPermission(Operations.UpdateContract),
              redirectTo: '/not-found',
            },
          },
        ],
      },
    ],
  },
];
