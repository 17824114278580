import { parseAuthToken } from '@/utils/helpers/token';
import { hasPermission } from '@/utils/helpers/userPermission';
import { ALL_CAMPUSES_SUFFIX } from '@/utils/constants/roles';
import { getAccessToken } from '@/utils/helpers/authHelpers';

export const hasMultipleCampuses = (): boolean => {
  const token = getAccessToken();
  if (!token) return false;

  const { managementRoles, roles } = parseAuthToken(token);

  if (managementRoles.length > 0) {
    return true;
  }

  return roles.length > 1;
};

export const getCampusId = (): string => {
  const token = getAccessToken();
  const { currentCampusID } = parseAuthToken(token as string);
  return currentCampusID || '';
};

export const removeCampusId = () => {
  localStorage.removeItem('E12_SELECTED_CAMPUS');
};

export const getOrganizationId = (): string => {
  const token = getAccessToken();
  const { organizationID } = parseAuthToken(token as string);
  return organizationID || '';
};

export const hasAllCampusesAccess = (permission: string) => {
  return hasPermission(`${permission}${ALL_CAMPUSES_SUFFIX}`);
};

export const checkAccessPage = (campusID: string, permission: string) => {
  if (hasAllCampusesAccess(permission)) {
    return false;
  }
  return campusID !== getCampusId();
};
