import { GUID } from '@/types/common.types';
import { FileTypes } from '@/utils/enums/FileTypes';

export interface IUploadFileResponse {
  campusID?: GUID;
  type?: FileTypes;
  file: File;
  folder?: FolderType;
}

export enum FolderType {
  E12Employee = 'e12-employee',
  Lessons = 'lessons',
  ClassAnnouncements = 'class-announcements',
  Demands = 'demands',
  Attendance = 'attendance',
  Homework = 'homework',
  Study = 'study',
  LessonContents = 'lesson-contents',
  QuestionPool = 'question-pool',
  Employee = 'employee',
  Students = 'students',
  Infirmary = 'infirmary',
  Groups = 'groups',
  Societies = 'societies',
  PreAccounting = 'preaccounting',
  Solver = 'solver',
}
