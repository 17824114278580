const TAG_TYPES = {
  USER: 'User',
  EMPLOYEE: 'Employee',
  STUDENTS: 'Students',
  DEPARTMENTS: 'Departments',
  CITIES: 'Cities',
  DISTRICTS: 'Districts',
  ORGANIZATIONS: 'organizations',
  MY_ORGANIZATION: 'MyOrganization',
  CLASSES: 'Classes',
  STUDY_FIELDS: 'StudyFields',
  CAMPUSES: 'Campuses',
  GRADES: 'Grades',
  ORGANIZATON: 'Organization',
  BRANCHES: 'Branches',
  CURRICULUM: 'Curriculum',
  LEARNING_DOMAIN: 'LearningDomain',
  SUB_LEARNING_DOMAIN: 'SubLearningDomain',
  LESSONS: 'Lessons',
  SPECIALTIES: 'Specialties',
  SUBJECT: 'Subject',
  LEARNING_OUTCOMES: 'LearningOutcomes',
  SUB_LEARNING_OUTCOMES: 'SubLearningOutcomes',
  LESSON_CONTENTS: 'LessonContents',
  ANNUAL_PLANS: 'AnnualPlans',
  ANNUAL_PLAN_WEEKS: 'AnnualPlanWeeks',
  FILE: 'File',
  PLACES: 'Places',
  UNIT_TIMELINES: 'UnitTimelines',
  CLASS_SCHEDULES: 'ClassSchedules',
  VIDEO_POOL: 'VideoPool',
  GROUPS: 'Groups',
  SOCIETIES: 'Societies',
  DEMANDS: 'Demands',
  ANNOUNCEMENTS: 'Announcement',
  MY_ANNOUNCEMENTS: 'MyAnnouncement',
  MEETINGS: 'Meetings',
  CAMPUS_ANNOUNCEMENTS: 'CampusAnnouncements',
  ATTENDANCE: 'Attendance',
  LIVE_LESSONS: 'LiveLessons',
  HOMEWORKS: 'Homeworks',
  HOMEWORK_ASSIGNMENTS: 'HomeworkAssignments',
  PAYMENT_PLANS: 'PaymentPlans',
  QUESTION_POOL: 'QuestionPool',
  QUIZ_POOL: 'QuizPool',
  VAULT_CASHES: 'VaultCashes',
  STUDY: 'Study',
  EXPENSES: 'Expenses',
  SUPPLIERS: 'Suppliers',
  PAYMENT_COLLECTION: 'PaymentCollection',
  INFIRMARY: 'Infirmary',
  TRACKING_NOTE: 'TrackingNote',
  ROLES: 'roles',
  PERMISSIONS: 'permissions',
  STUDENT_ACTIVITY_REPORTS: 'StudentActivityAndReports',
  TEMPLATE: 'Template',
  AI_CONFIG: 'AIConfig',
  AUDIT: 'Audit',
  SOLVER: 'Solver',
  CHATBOT: 'Chatbot',
  CONTRACTS: 'Contracts',
  NOTIFICATION_CONFIGS: 'NotificationConfigs',
  LANDING_PAGE: 'LandingPage',
};

export default TAG_TYPES;
