import { createSlice } from '@reduxjs/toolkit';

export interface CommonState {
  accessToken: string;
  currentCampusID: string;
  permissions: string[];
}

const initialState: CommonState = {
  accessToken: '',
  currentCampusID: '',
  permissions: [],
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    saveAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    removeAccessToken: (state) => {
      state.accessToken = '';
    },
    setCurrentCampusID: (state, action) => {
      state.currentCampusID = action.payload;
    },
  },
});

export const { setCurrentCampusID, saveAccessToken, removeAccessToken } =
  commonSlice.actions;

export default commonSlice.reducer;
