import React from 'react';
import { formatFilterValue } from '@/utils/helpers/export';

interface FilterItemProps {
  filterKey: string;
  value: unknown;
  dictionary: Record<string, string>;
}

export const FilterItem: React.FC<FilterItemProps> = React.memo(
  ({ filterKey, value, dictionary }) => (
    <div className="bg-gray-50 dark:bg-gray-700 p-4 border border-gray-200 dark:border-gray-600 rounded-lg shadow-sm">
      <label className="text-xs font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wide">
        {dictionary[filterKey] || filterKey.replace('_', ' ')}{' '}
      </label>
      <div className="text-sm font-medium text-gray-900 dark:text-gray-100 mt-1 break-all">
        {formatFilterValue(filterKey, value)}
      </div>
    </div>
  )
);

FilterItem.displayName = 'FilterItem';
